import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { AsyncResult, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackPrice?: any;
  callbackName?: any;
  callbackCategory?: any;
  callbackDedicatedBy?: any;
  callbackNamesEntered?: any;
  callbackPricesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
}

const AmountDeceasedNameForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackPrice, callbackName, callbackCategory, callbackDedicatedBy,
  callbackNamesEntered, callbackPricesEntered,
  formItems, config, localeCode, actionContext, isMobile, isEditMode, allowMultipleRegistrations }) => {
  var minDonationNumberOption = Number(formScheme[0].field_options![0]);
  var minDonationstringOption = formScheme[0].field_options![0];

  const [customPrice, setCustomPrice] = useState([minDonationNumberOption]);
  const [inputValue, setInputValue] = useState(['']);
  const [donationValue, setDonationValue] = useState([minDonationstringOption]);
  const [radioValue, setRadioValue] = useState([resource.categoryFamilyLabel[`${localeCode}`]]);
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [dedicatedBy, setDedicatedBy] = useState(['']);

  const formLabels = resource.amountDeceasedNameForm[`${localeCode}`];

  const noOfNames = formScheme.find(form => form.field_name === 'name')!.field_number!;
  const deceasedNames = formScheme.find(form => form.field_name === 'name')!.field_number3!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _onChangePriceValue = (event: any, index: number) => {
    _setDonationValueArray(event.target.value, index);

    if (event.target.value === resource.otherLabel[`${localeCode}`]) {
      _setInputValueArray('0', index);
      _setCustomPriceArray(0, index);
    } else {
      _setCustomPriceArray(Number(event.target.value), index);
      _setInputValueArray('', index);
    }
  };

  const _onChangeRadioValue = (event: any, index: number) => {
    _setRadioValueArray(event.target.value, index);

    let ceremonyNameArray = ceremonyName.slice();
    let existingCeremonyName = ceremonyNameArray[`${index}`];
    let existingNameArray = existingCeremonyName.split(';');

    let dedicatedNameArray = dedicatedBy.slice();

    dedicatedNameArray[`${index}`] = '';

    if (event.target.value === resource.categoryFamilyLabel[`${localeCode}`]) {
      if (existingNameArray.length < noOfNames) {
        existingCeremonyName += ';'.repeat(noOfNames - existingNameArray.length - 1);
      } else { // More than
        while (existingNameArray.length > noOfNames) {
          existingNameArray.pop();
        }
        existingCeremonyName = existingNameArray.join(';');
      }
    } else {
      if (existingNameArray.length < deceasedNames) {
        existingCeremonyName += ';'.repeat(deceasedNames - existingNameArray.length - 1);
      } else { // More than
        while (existingNameArray.length > deceasedNames) {
          existingNameArray.pop();
        }
        existingCeremonyName = existingNameArray.join(';');
      }
    }

    ceremonyNameArray[`${index}`] = existingCeremonyName;

    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedNameArray);

    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedNameArray);
  };

  const _setCustomPriceArray = (price: number, index: number) => {
    const customPriceArray = customPrice.slice();

    while (typeof customPriceArray[`${index}`] === 'undefined') {
      customPriceArray.push(minDonationNumberOption);
    }

    customPriceArray[`${index}`] = price;

    setCustomPrice(customPriceArray);

    callbackPrice(customPriceArray);
  };

  const _setDonationValueArray = (value: string, index: number) => {
    const donationValueArray = donationValue.slice();

    const element = formScheme.find(element => element.field_name === 'amount');

    while (typeof donationValueArray[`${index}`] === 'undefined' || donationValueArray[`${index}`] === "0") {
      donationValueArray.push(element!.field_options![0]);
    }

    donationValueArray[`${index}`] = value !== '' ? value : element!.field_options![0];

    setDonationValue(donationValueArray);
  };

  const isNumeric = (num: any): boolean => { return !isNaN(num); };

  const _setInputValue = (event: any, index: number) => {
    if (isNumeric(event.target.value)) {
      _setInputValueArray(event.target.value, index);
      _setDonationValueArray(resource.otherLabel[`${localeCode}`], index);
      _setCustomPriceArray(Number(event.target.value), index);
    }
  };

  const _setInputValueArray = (value: string, index: number) => {
    let inputValueArray = inputValue.slice();

    while (typeof inputValueArray[`${index}`] === 'undefined') {
      inputValueArray.push('');
    }

    inputValueArray[`${index}`] = value;

    setInputValue(inputValueArray);
  };

  const _setRadioValueArray = (value: string, index: number) => {
    let radioValueArray = radioValue.slice();

    while (typeof radioValueArray[`${index}`] === 'undefined') {
      radioValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    radioValueArray[`${index}`] = value;

    setRadioValue(radioValueArray);

    callbackCategory(radioValueArray);
  };

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];

    if (!inputMultipleName.includes(';')) {
      inputMultipleName += ';'.repeat(noOfNames - 1);
    }

    let inputMutlipleNameSplit = inputMultipleName.split(';');

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(';');

    _setCeremonyNameArray(inputName, index);
    // _setCustomPriceArray(0, index);
  };

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedBy, radioValue);
  };

  const _setDedicatedByValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;
    _setDedicatedByArray(event.target.value.substring(0, maxLength - 1), index);

  };

  const _setDedicatedByArray = (name: string, index: number) => {
    let dedicatedByArray = dedicatedBy.slice();
    while (typeof dedicatedByArray[`${index}`] === 'undefined') {
      dedicatedByArray.push('');
    }
    dedicatedByArray[`${index}`] = name;
    setDedicatedBy(dedicatedByArray);
    callbackDedicatedBy(dedicatedByArray);
    _checkNamesEntered(ceremonyName, dedicatedByArray, radioValue);
  };

  const _checkNamesEntered = (ceremonyNameArray: string[], dedicatedByArray: string[], categoryArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }

      if (categoryArray[`${i}`] === resource.categoryDeceasedLabel[`${localeCode}`] && dedicatedByArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _addItem = () => {
    addRow(1);

    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const donationValueArray = donationValue.slice();
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    customPriceArray.push(parseInt(element!.field_options![0]));
    donationValueArray.push(element!.field_options![0]);
    categoryValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    ceremonyNameArray.push('');
    dedicatedByArray.push('');

    setCustomPrice(customPriceArray);
    setDonationValue(donationValueArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackPrice(customPriceArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const inputValueArray = inputValue.slice();
    const donationValueArray = donationValue.slice();
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();
    const dedicatedByArray = dedicatedBy.slice();

    if (typeof customPriceArray[`${index}`] !== 'undefined') {
      if (customPriceArray.length > 1) {
        customPriceArray.splice(index, 1);
      } else {
        customPriceArray[0] = parseInt(element!.field_options![0]);
      }
    }

    if (typeof inputValueArray[`${index}`] !== 'undefined') {
      if (inputValueArray.length > 1) {
        inputValueArray.splice(index, 1);
      } else {
        inputValueArray[0] = '';
      }
    }

    if (typeof donationValueArray[`${index}`] !== 'undefined') {
      if (donationValueArray.length > 1) {
        donationValueArray.splice(index, 1);
      } else {
        donationValueArray[0] = element!.field_options![0];
        customPriceArray[0] = minDonationNumberOption;
      }
    }

    if (typeof categoryValueArray[`${index}`] !== 'undefined') {
      if (categoryValueArray.length > 1) {
        categoryValueArray.splice(index, 1);
      } else {
        categoryValueArray[0] = resource.categoryFamilyLabel[`${localeCode}`];
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    if (typeof dedicatedByArray[`${index}`] !== 'undefined') {
      if (dedicatedByArray.length > 1) {
        dedicatedByArray.splice(index, 1);
      } else {
        dedicatedByArray[0] = '';
      }
    }

    setCustomPrice(customPriceArray);
    setInputValue(inputValueArray);
    setDonationValue(donationValueArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);
    setDedicatedBy(dedicatedByArray);

    callbackPrice(customPriceArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);
    callbackDedicatedBy(dedicatedByArray);

    _checkNamesEntered(ceremonyNameArray, dedicatedByArray, categoryValueArray);
  }

  useEffect(() => {
    const element = formScheme.find(element => element.field_name === 'amount');

    var minDonationvalue = Number(element?.field_options![0]);
    var minDonationString = String(element?.field_options![0]);

    const existingDonationValue: string[] = [];
    const existingInputValue: string[] = [];
    const existingCustomPriceAmount: number[] = [];
    const existingRadioValue: string[] = [];
    const existingCeremonyName: string[] = [];
    const existingDedicatedBy: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        var customPriceAmount: number = formItems[`${i}`]['customPriceAmount'];
        const itemCategory = formItems[`${i}`]['category'];
        const itemNames: string[] = formItems[`${i}`]['names'];

        switch (customPriceAmount) {
          case 0:
            customPriceAmount = Number(minDonationvalue);
            existingDonationValue.push(minDonationString);
            existingInputValue.push('');
            break;

          case 2:
            existingDonationValue.push('2');
            existingInputValue.push('');
            break;

          case 5:
            existingDonationValue.push('5');
            existingInputValue.push('');
            break;

          case 10:
            existingDonationValue.push('10');
            existingInputValue.push('');
            break;

          case 50:
            existingDonationValue.push('50');
            existingInputValue.push('');
            break;

          default:
            if (customPriceAmount) {
              existingDonationValue.push(resource.otherLabel[`${localeCode}`]);
              existingInputValue.push(customPriceAmount.toString());
            } else {
              customPriceAmount = Number(minDonationvalue);
              existingDonationValue.push(minDonationString);
              existingInputValue.push('');
            }
            break;
        }

        let itemNameCount = 0;

        if (itemCategory === resource.categoryFamilyLabel[`${localeCode}`]) {
          itemNameCount = noOfNames;
        } else {
          itemNameCount = deceasedNames;
        }

        let itemName = '';

        itemNames.map((name, index) => {
          if (index != 0 && index < itemNameCount) {
            itemName += ';' + name;
          } else {
            itemName += name;
          }
        });

        existingCustomPriceAmount.push(customPriceAmount);
        existingRadioValue.push(itemCategory);
        existingCeremonyName.push(itemName);
        existingDedicatedBy.push(formItems[`${i}`]['dedicatedBy']);
      }

      setCeremonyName(existingCeremonyName);
      setDedicatedBy(existingDedicatedBy);

      callbackName(existingCeremonyName);
      callbackDedicatedBy(existingDedicatedBy);

      _checkNamesEntered(existingCeremonyName, existingDedicatedBy, existingRadioValue);
    } else {
      existingDonationValue.push(minDonationString);     
      existingCustomPriceAmount.push(minDonationvalue); 
      existingRadioValue.push(resource.categoryFamilyLabel[`${localeCode}`]);
      existingInputValue.push('');
    }

    setDonationValue(existingDonationValue);
    setInputValue(existingInputValue);
    setCustomPrice(existingCustomPriceAmount);
    setRadioValue(existingRadioValue);
  
    callbackPrice(existingCustomPriceAmount);
    callbackCategory(existingRadioValue);

    callbackPricesEntered(true);
  }, [formItems]);

  const _buildAmountField = (element: IField, idx: number): JSX.Element | null => {
    if (element.field_options) {
      return (
        <div className='row'>
          <div className='col-12 form-field'>
            <p className='form-label'>{formLabels.amount}</p>
            {element.field_options.map((option: string) => {
              return (
                <label className='radio-option'>
                  <input
                    type="radio"
                    value={option}
                    checked={donationValue[`${idx}`] === String(option)}
                    aria-checked={donationValue[`${idx}`] === String(option)}
                    onChange={(event) => _onChangePriceValue(event, idx)}
                  />

                  {option === resource.otherLabel[`${localeCode}`] ? '' : '$'}{option}

                  {option !== resource.otherLabel[`${localeCode}`] ? null :
                    <input
                      type='text'
                      value={inputValue[`${idx}`]}
                      inputMode='numeric'
                      pattern='[0-9]*'
                      aria-live='polite'
                      role='spinbutton'
                      aria-valuemin={10}
                      aria-valuemax={10000}
                      aria-valuenow={Number(inputValue[`${idx}`])}
                      onChange={(event) => _setInputValue(event, idx)}
                      onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                      onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
                      className='form-control form-control-other'
                    />}
                </label>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  }

  const _buildCategoryField = (idx: number): JSX.Element | null => {
    if (typeof radioValue[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.category}</p>
          <div className='row'>
            <label className='col-lg-3 radio-option'>
              <input type='radio' value={resource.categoryFamilyLabel[`${localeCode}`]} checked={radioValue[`${idx}`].valueOf() === String(resource.categoryFamilyLabel[`${localeCode}`]).valueOf()} aria-checked={radioValue[`${idx}`].valueOf() === String(resource.categoryFamilyLabel[`${localeCode}`]).valueOf()} onChange={(event) => _onChangeRadioValue(event, idx)} />{resource.categoryFamilyLabel[`${localeCode}`]}
            </label>
            <label className='col-lg-3 radio-option'>
              <input type='radio' value={resource.categoryDeceasedLabel[`${localeCode}`]} checked={radioValue[`${idx}`].valueOf() === String(resource.categoryDeceasedLabel[`${localeCode}`])} aria-checked={radioValue[`${idx}`].valueOf() === String(resource.categoryDeceasedLabel[`${localeCode}`]).valueOf()} onChange={(event) => _onChangeRadioValue(event, idx)} />{resource.categoryDeceasedLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    )
  }

  const _buildNameGroup = (idx: number, noOfNames: number, isDeceased: boolean): JSX.Element[] => {
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            {isDeceased && <p className='form-label'>{formLabels.deceasedName}</p>}
            {!isDeceased && <p className='form-label'>{formLabels.name.endsWith('*') ? nameIndex === 0 ? formLabels.name.substring(0, formLabels.name.length - 1) + ' ' + (nameIndex + 1) + '*' : formLabels.name.replace('*', ' ' + (nameIndex + 1)) : formLabels.name + ' ' + nameIndex + 1}</p>}
            <input
              type='text'
              inputMode='text'
              value={_displayName(ceremonyName[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={(event) => _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            />
          </div>
        </div>
      );
    });
  }

  const _buildDedicatedBy = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.dedicatedBy}</p>
          <input
            type='text'
            inputMode='text'
            value={dedicatedBy[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setDedicatedByValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'amount') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildAmountField(element, idx)}
                          {_buildCategoryField(idx)}
                          {radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`]) && noOfNames > 0 && _buildNameGroup(idx, noOfNames, radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`]))}
                          {radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`]) && deceasedNames > 0 && _buildNameGroup(idx, deceasedNames, radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`]))}
                          {radioValue[`${idx}`] === String(resource.categoryDeceasedLabel[`${localeCode}`]) && deceasedNames > 0 && _buildDedicatedBy(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price! === 0 ? typeof customPrice[`${idx}`] !== 'undefined' ? customPrice[`${idx}`] : '0' : selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>
        }
      </form>
    </div>
  );
};

export default AmountDeceasedNameForm;