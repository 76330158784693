import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import DatePicker from 'react-date-picker';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';
import { toast } from 'react-toastify';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync, uploadImageFileToAzure } from '../../../actions/DataActionExtension.g';

import { IEventDetails, IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

import { Response } from '../../../components/enums';

import Select from 'react-select'

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackPrice?: any;
  callbackName?: any;
  callbackEmailId?: any;
  callbackContactNumber?: any;
  callbackEventID?: any;
  callbackEvent?: any;
  callbackNamesEntered?: any;
  callbackPricesEntered?: any;
  callbackMultiplier: any;
  callbackEventsSelected?: any;
  callbackPhoneNumber: any;
  callbackNextOfKinName: any;
  callbackNextOfKinPhoneNumber: any;
  callbackBirthDate: any;
  callbackPassportNumber: any;
  callbackPassportExpiryDate:any;
  callbackPassportIssuedDate: any;
  callbackConsent: any;
  callbackDateEntered: any;
  callbackPassportExpiryDateEntered: any;
  callbackPassportIssueDateEntered: any;
  callbackRemarks?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  userInfo: any;
  productEvents: any[];
  isUat: boolean;
  organisation: string;
  azureContainerName: string;
  callbackPhoneNumbersEntered: any;
  callbackPassportNumberEntered: any;
  callbackFileUploaded: any;
  isFileUploaded: boolean;
  allowMultipleRegistrations: boolean;
  showRemarksField: boolean;
}

const EventPassportUploadForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackPrice, callbackName, callbackNamesEntered, callbackPricesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, userInfo, productEvents, callbackEventID, callbackEvent, callbackMultiplier, callbackEventsSelected, callbackPhoneNumber, callbackNextOfKinName, callbackPhoneNumbersEntered, callbackNextOfKinPhoneNumber, callbackBirthDate, callbackDateEntered, isUat, organisation, azureContainerName,callbackPassportNumberEntered,callbackPassportExpiryDateEntered,callbackPassportIssueDateEntered, callbackFileUploaded,isFileUploaded,allowMultipleRegistrations,
callbackPassportNumber, callbackPassportExpiryDate, callbackPassportIssuedDate,callbackConsent,callbackRemarks, showRemarksField }) => {

  const [customPrice, setCustomPrice] = useState([0]);
  const [inputValue, setInputValue] = useState(['']);
  const [attendeeName, setAttendeeName] = useState(['']);
  const [coursesEventId, setCoursesEventId] = useState(['']);
  const [selectValue, setSelectValue] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [chineseName, setChineseName] = useState(['']);
  const [nextOfKinName, setNextOfKinName] = useState(['']);
  const [nextOfKinPhoneNumbers, setNextOfKinPhoneNumbers] = useState(['']);
  const [birthDate, setBirthDate] = useState(['']);
  const [passportNumber, setPassportNumber] = useState(['']);
  const [passportExpiryDate, setPassportExpiryDate] = useState(['']);
  const [passportIssueDate, setPassportIssueDate] = useState(['']);
  const [multipliers, setMultiplers] = useState([1]);
  const [remainingSlots, setRemainingSlots] = useState(0);
  const [file, setFile] = useState('');
  const [imageFiles, setImageFiles ] = useState(['']);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [consent, setConsent] = useState([1]);
  const [remark, setRemark] = useState(['']);
  const[showAddMoreButton, setShowAddMoreButton] = useState(false);

  const formLabels = resource.eventsPassportUploadForm[`${localeCode}`];

  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();


  const _setNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setAttendeeNameArray(event.target.value.substring(0, maxLength - 1), index);


  };

  const _setNextOfKinNameArray = (name: string, index: number) => {
    const nextOfKinNameArray = nextOfKinName.slice();

    while (typeof nextOfKinNameArray[`${index}`] === 'undefined') {
      nextOfKinNameArray.push('');
    }

    nextOfKinNameArray[`${index}`] = name;

    nextOfKinNameArray[`${index}`].trim();

    setNextOfKinName(nextOfKinNameArray);

    callbackNextOfKinName(nextOfKinNameArray);

  };

  const _setNextOfKinPhoneNumbers = (value: string, index: number) => {
    const valueArray = nextOfKinPhoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setNextOfKinPhoneNumbers(valueArray);

    callbackNextOfKinPhoneNumber(valueArray);
  }

  const _setPassportNumber = (value: string, index: number) => {
    const valueArray = passportNumber.slice();

    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }
  
    if (alphanumericRegex.test(value) && value.length <= 50) {
      valueArray[`${index}`] = value;

      setPassportNumber(valueArray);
      _checkPassportNumberEntered(valueArray);
      callbackPassportNumber(valueArray);
    }
    else {
      toast.error(formLabels.validPassportText)
    }
  }

  const _setPassportExpiryDate = (value: Date, index: number) => {
    const valueArray = passportExpiryDate.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value ? value.toISOString() : '';

    setPassportExpiryDate(valueArray);
    _checkPassportExpiryDateEntered(valueArray);
    callbackPassportExpiryDate(valueArray);
  }

  const _setPassportIssueDate = (value: Date, index: number) => {
    const valueArray = passportIssueDate.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value ? value.toISOString() : '';

    setPassportIssueDate(valueArray);
    _checkPassportIssueDateEntered(valueArray);
    callbackPassportIssuedDate(valueArray);
  }

  const _setBirthDate = (value: Date, index: number) => {
    const valueArray = birthDate.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value ? value.toISOString() : '';

    setBirthDate(valueArray);

    callbackBirthDate(valueArray);

    _checkDateEntered(valueArray);
  }

  const _checkDateEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (!valueArray[`${i}`]) {
        checked = false; break;
      }
    }

    callbackDateEntered(checked);
  }

  const _checkPassportExpiryDateEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (!valueArray[`${i}`]) {
        checked = false; break;
      }
    }

    callbackPassportExpiryDateEntered(checked);
  }

  const _checkPassportIssueDateEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (!valueArray[`${i}`]) {
        checked = false; break;
      }
    }

    callbackPassportIssueDateEntered(checked);
  }

  const _setAttendeeNameArray = (name: string, index: number) => {
    const attendeeNameArray = attendeeName.slice();

    while (typeof attendeeNameArray[`${index}`] === 'undefined') {
      attendeeNameArray.push('');
    }

    attendeeNameArray[`${index}`] = name;

    attendeeNameArray[`${index}`].trim();

    setAttendeeName(attendeeNameArray);

    callbackName(attendeeNameArray);

    _checkNamesEntered(attendeeNameArray);
  };

  const _setPhoneNumber = (value: string, index: number) => {
    const valueArray = phoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setPhoneNumbers(valueArray);

    callbackPhoneNumber(valueArray);

    _checkPhoneNumberEntered(valueArray);
  }

  const _setNextOfKinNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setNextOfKinNameArray(event.target.value.substring(0, maxLength - 1), index);


  };

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const _validateFileSize = (file: any) => {
    const MAX_FILE_SIZE = 2e+7 // 20MB
    const fileSizeKiloBytes = file.size;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return false;
    }
    return true;
  }

  const _setFileInputValue = async (event: any, index: number) => {
    const imageFile = event.target.files[0];
    if (_validateFileSize(imageFile)) {
      const base64 = await convertToBase64(imageFile) as string;
      const base64String = base64.toString().replace(/^data:image\/\w+;base64,/, "");
      setFile(base64String);

      const imageFileArray = imageFiles.slice();

      while (typeof imageFileArray[`${index}`] === 'undefined') {
        imageFileArray.push('');
      }
  
      imageFileArray[`${index}`] = imageFile.name;
  
      imageFileArray[`${index}`].trim();

      setImageFiles(imageFileArray);
      setIsValidFileSize(true);
    }
    else {
      setIsValidFileSize(false);
      toast.error(formLabels.fileSizeErrorMsg);
    }
  };


  const _checkNamesEntered = (attendeeNameArray: string[]) => {
    let namesEntered = attendeeNameArray.length > 0 ? true : false;

    for (let i = 0; i < attendeeNameArray.length; i++) {
      if (attendeeNameArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkFileUploaded = (imageFileArray: string[]) => {
    let imageFileUploaded = imageFileArray.length > 0 ? true : false;

    for (let i = 0; i < imageFileArray.length; i++) {
      if (imageFileArray[`${i}`].trim() === '') {
        imageFileUploaded = false;
        break;
      }
    }

    callbackFileUploaded(imageFileUploaded);
  }

  const _checkPhoneNumberEntered = (phoneNumbersArray: string[]) => {
    let phoneNumbersEntered = phoneNumbersArray.length > 0 ? true : false;

    for (let i = 0; i < phoneNumbersArray.length; i++) {
      if (phoneNumbersArray[`${i}`].trim() === '') {
        phoneNumbersEntered = false;
        break;
      }
    }

    callbackPhoneNumbersEntered(phoneNumbersEntered);
  }

  const _checkPassportNumberEntered = (passportNumbersArray: string[]) => {
    let passportNumbersEntered = passportNumbersArray.length > 0 ? true : false;

    for (let i = 0; i < passportNumbersArray.length; i++) {
      if (passportNumbersArray[`${i}`].trim() === '') {
        passportNumbersEntered = false;
        break;
      }
    }

    callbackPassportNumberEntered(passportNumbersEntered);
  }

  const _checkPricesEntered = (customPriceArray: number[]) => {
    let pricesEntered = true;

    for (let i = 0; i < customPriceArray.length; i++) {
      if (customPriceArray[`${i}`] == 0) {
        pricesEntered = false;
        break;
      }
    }

    callbackPricesEntered(pricesEntered);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _addItem = () => {
    addRow(1);

    const attendeeNameArray = attendeeName.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const nextOfKinNamesArray = nextOfKinName.slice();
    const nextOfKinPhoneNumbersArray = nextOfKinPhoneNumbers.slice();
    const chineseNamesArray = chineseName.slice();
    const birthdayArray = birthDate.slice();
    const passportNumberArray = passportNumber.slice();
    const passportIssueDateArray = passportIssueDate.slice();
    const passportExpiryDateArray = passportExpiryDate.slice();
    const imageFileArray = imageFiles.slice();
    const consentArray = consent.slice();
    const remarkArray = remark.slice();


    birthdayArray.push('');
    passportIssueDateArray.push('');
    passportExpiryDateArray.push('');
    imageFileArray.push('');
    passportNumberArray.push('');
    consentArray.push(1);
    remarkArray.push('');
 
    setShowAddMoreButton(false);
    setRemainingSlots(0);
    setAttendeeName(attendeeNameArray);
    setSelectValue(coursesEventArray);
    setCoursesEventId(coursesEventIDArray);
    setPhoneNumbers(phoneNumbersArray);
    setChineseName(chineseNamesArray);
    setNextOfKinName(nextOfKinNamesArray);
    setNextOfKinPhoneNumbers(nextOfKinPhoneNumbersArray);
    setPassportNumber(passportNumberArray);
    setPassportIssueDate(passportIssueDateArray);
    setPassportExpiryDate(passportExpiryDateArray)
    setBirthDate(birthdayArray);
    setImageFiles(imageFileArray)
    setConsent(consentArray);
    setRemark(remarkArray);

    callbackEvent(coursesEventArray);
    callbackEventID(coursesEventIDArray);
    callbackName(attendeeNameArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackNextOfKinName(nextOfKinNamesArray);
    callbackNextOfKinPhoneNumber(nextOfKinPhoneNumbersArray);
    callbackBirthDate(birthdayArray);
    callbackPassportNumber(passportNumberArray);
    callbackPassportExpiryDate(passportExpiryDateArray);
    callbackPassportIssuedDate(passportIssueDateArray);
    callbackConsent(consentArray);
    callbackRemarks(remarkArray);

    callbackNamesEntered(false);
    callbackEventsSelected(false);
    callbackDateEntered(false);

    _checkPassportNumberEntered(passportNumberArray);
    _checkPassportExpiryDateEntered(passportExpiryDateArray);
    _checkPassportIssueDateEntered(passportIssueDateArray);
    _checkFileUploaded(imageFileArray);

    if(productEvents.length === 1)
    {
      
    const event = productEvents[0];
    const eventObj = { value: event['id'], label: event['name'] }
     
    _setEventDates(eventObj, coursesEventArray.length);
    callbackEventsSelected(true);

    }

  }

  const _uploadFileToAzure = async (index: number) => {
    const env = isUat ? "uat" : "prod";
    const entity = organisation == "BT" ? "btrts" : "gpbt";
    const fileName = passportNumber[`${index}`] + ".jpeg";
    const base64Image = file;
    const containerName = azureContainerName;

    const imageFileArray = imageFiles.slice();


    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;

    if (alphanumericRegex.test(passportNumber[`${index}`])) {

      const uploadFileResult = await uploadImageFileToAzure({ callerContext: actionContext }, env, entity, fileName, base64Image, containerName);

      if (uploadFileResult.toUpperCase() !== Response.SUCCESS) {
        toast.error(formLabels.fileUploadFailedMsg);
      }
      else {

        setImageFiles(imageFileArray);
        _checkFileUploaded(imageFileArray);
       // setIsFileUploaded(true);
        toast(formLabels.fileUploadSuccessMsg);
      }

    }
    else {
      toast.error(formLabels.validPassportText)
    }



  }

  const _updateStateData = (index: number) => {
    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const inputValueArray = inputValue.slice();
    const attendeeNameArray = attendeeName.slice();
    const multiplierArray = multipliers.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const chineseNamesArray = chineseName.slice();
    const nextOfKinNamesArray = nextOfKinName.slice();
    const nextOfKinPhoneNumbersArray = nextOfKinPhoneNumbers.slice();
    const birthdayArray = birthDate.slice();
    const passportNumberArray = passportNumber.slice();
    const passportIssueDateArray = passportIssueDate.slice();
    const passportExpiryDateArray = passportExpiryDate.slice();
    const imageFileArray = imageFiles.slice();
    const consentArray = consent.slice();
    const remarkArray = remark.slice();


    if (typeof customPriceArray[`${index}`] !== 'undefined') {
      if (customPriceArray.length > 1) {
        customPriceArray.splice(index, 1);
      } else {
        customPriceArray[0] = parseInt(element!.field_options![0]);
      }
    }

    if (typeof inputValueArray[`${index}`] !== 'undefined') {
      if (inputValueArray.length > 1) {
        inputValueArray.splice(index, 1);
      } else {
        inputValueArray[0] = '';
      }
    }

    if (typeof attendeeNameArray[`${index}`] !== 'undefined') {
      if (attendeeNameArray.length > 1) {
        attendeeNameArray.splice(index, 1);
      } else {
        attendeeNameArray[0] = '';
      }
    }
    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = 1;
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof coursesEventArray[`${index}`] !== 'undefined') {
      if (coursesEventArray.length > 1) {
        coursesEventArray.splice(index, 1);
      } else {
        coursesEventArray[0] = '';
      }
    }

    if (typeof coursesEventIDArray[`${index}`] !== 'undefined') {
      if (coursesEventIDArray.length > 1) {
        coursesEventIDArray.splice(index, 1);
      } else {
        coursesEventIDArray[0] = '';
      }
    }

    if (typeof phoneNumbersArray[`${index}`] !== 'undefined') {
      if (phoneNumbersArray.length > 1) {
        phoneNumbersArray.splice(index, 1);
      } else {
        phoneNumbersArray[0] = '';
      }
    }

    if (typeof chineseNamesArray[`${index}`] !== 'undefined') {
      if (chineseNamesArray.length > 1) {
        chineseNamesArray.splice(index, 1);
      } else {
        chineseNamesArray[0] = '';
      }
    }

    if (typeof nextOfKinNamesArray[`${index}`] !== 'undefined') {
      if (nextOfKinNamesArray.length > 1) {
        nextOfKinNamesArray.splice(index, 1);
      } else {
        nextOfKinNamesArray[0] = '';
      }
    }

    if (typeof nextOfKinPhoneNumbersArray[`${index}`] !== 'undefined') {
      if (nextOfKinPhoneNumbersArray.length > 1) {
        nextOfKinPhoneNumbersArray.splice(index, 1);
      } else {
        nextOfKinPhoneNumbersArray[0] = '';
      }
    }
    if (typeof birthdayArray[`${index}`] !== 'undefined') {
      if (birthdayArray.length > 1) {
        birthdayArray.splice(index, 1);
      } else {
        birthdayArray[0] = '';
      }
    }
    if (typeof passportNumberArray[`${index}`] !== 'undefined') {
      if (passportNumberArray.length > 1) {
        passportNumberArray.splice(index, 1);
      } else {
        passportNumberArray[0] = '';
      }
    }
    if (typeof passportExpiryDateArray[`${index}`] !== 'undefined') {
      if (passportExpiryDateArray.length > 1) {
        passportExpiryDateArray.splice(index, 1);
      } else {
        passportExpiryDateArray[0] = '';
      }
    }
    if (typeof passportIssueDateArray[`${index}`] !== 'undefined') {
      if (passportIssueDateArray.length > 1) {
        passportIssueDateArray.splice(index, 1);
      } else {
        passportIssueDateArray[0] = '';
      }
    }
    if (typeof imageFileArray[`${index}`] !== 'undefined') {
      if (imageFileArray.length > 1) {
        imageFileArray.splice(index, 1);
      } else {
        imageFileArray[0] = '';
      }
    }
    if (typeof consentArray[`${index}`] !== 'undefined') {
      if (consentArray.length > 1) {
        consentArray.splice(index, 1);
      } else {
        consentArray[0] = 1;
      }
    }
    if (typeof remarkArray[`${index}`] !== 'undefined') {
      if (remarkArray.length > 1) {
        remarkArray.splice(index, 1);
      } else {
        remarkArray[0] = '';
      }
    }



    setCustomPrice(customPriceArray);
    setInputValue(inputValueArray);
    setAttendeeName(attendeeNameArray);
    setSelectValue(coursesEventArray);
    setCoursesEventId(coursesEventIDArray);
    setPhoneNumbers(phoneNumbersArray);
    setChineseName(chineseNamesArray);
    setNextOfKinName(nextOfKinNamesArray);
    setNextOfKinPhoneNumbers(nextOfKinPhoneNumbersArray);
    setBirthDate(birthdayArray);
    setPassportNumber(passportNumberArray);
    setPassportIssueDate(passportIssueDateArray);
    setPassportExpiryDate(passportExpiryDateArray)
    setImageFiles(imageFileArray);
    setConsent(consentArray);
    setRemark(remarkArray);


    callbackEvent(coursesEventArray);
    callbackEventID(coursesEventIDArray);
    callbackName(attendeeNameArray);
    callbackPrice(customPriceArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackNextOfKinName(nextOfKinNamesArray);
    callbackNextOfKinPhoneNumber(nextOfKinPhoneNumbersArray);
    callbackBirthDate(birthdayArray);
    callbackPassportNumber(passportNumberArray);
    callbackPassportExpiryDate(passportExpiryDateArray);
    callbackPassportIssuedDate(passportIssueDateArray);
    callbackConsent(consentArray);
    callbackRemarks(remarkArray);
   

    _checkEventsEntered(coursesEventArray);
    _checkNamesEntered(attendeeNameArray);
    _checkFileUploaded(imageFileArray);
    _checkPricesEntered(customPriceArray);
    _checkPhoneNumberEntered(phoneNumbersArray);
    _checkPassportNumberEntered(passportNumberArray);
    _checkDateEntered(birthdayArray);
    _checkPassportExpiryDateEntered(passportExpiryDateArray);
    _checkPassportIssueDateEntered(passportIssueDateArray);
  }

  useEffect(() => {
    const element = formScheme.find(element => element.field_name === 'amount');

    var minDonationvalue = Number(element?.field_options![0]);
    var minDonationString = String(element?.field_options![0]);

    const existingCustomPriceAmount: number[] = [];
    const existingInputValue: string[] = [];
    const existingAttendeeName: string[] = [];
    const existingDonationAmount: string[] = [];
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const existingPhoneNumbersArray: string[] = [];
    const existingChineseNamesArray: string[] = [];
    const existingNextOfKinNamesArray: string[] = [];
    const existingNextOfKinPhoneNumbersArray: string[] = [];
    const existingBirthDate: string[] = [];
    const existingPassportNumber: string[] = [];
    const existingPassportIssueDate: string[] = [];
    const existingPassportExpiryDate: string[] = [];
    const imageFileArray: string[] = [];
    const existingConsent: number[] = [];
    const existingRemark: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        var customPriceAmount: number = formItems[`${i}`]['customPriceAmount'];

        const eventName: string = formItems[`${i}`]['eventName'];
        const birthDate = formItems[`${i}`]['birthDate'] !== '' ? new Date(formItems[`${i}`]['birthDate']) : null;
        const passportExpiryDate = formItems[`${i}`]['passportExpiryDate'] !== '' ? new Date(formItems[`${i}`]['passportExpiryDate']) : null;
        const passportIssueDate = formItems[`${i}`]['passportIssuedDate'] !== '' ? new Date(formItems[`${i}`]['passportIssuedDate']) : null;


        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        existingPhoneNumbersArray.push(formItems[`${i}`]['phoneNumber']);
        existingChineseNamesArray.push(formItems[`${i}`]['chineseName']);
        existingNextOfKinNamesArray.push(formItems[`${i}`]['nameNextKin']);
        existingNextOfKinPhoneNumbersArray.push(formItems[`${i}`]['contactNumberNextKin']);
        existingBirthDate.push(birthDate?birthDate.toISOString():'');
        existingPassportNumber.push(formItems[`${i}`]['passportNumber']);
        existingPassportIssueDate.push(passportIssueDate?passportIssueDate.toISOString():'');
        existingPassportExpiryDate.push(passportExpiryDate?passportExpiryDate.toISOString():'');
        existingAttendeeName.push(formItems[`${i}`]['names'][0]);
        existingConsent.push(1);
        existingCustomPriceAmount.push(customPriceAmount);
        existingRemark.push(formItems[`${i}`]['remarks']);
        imageFileArray.push('');

        if(productEvents.length === 1)
        {
          const event = productEvents[0];
          const eventObj = { value: event['id'], label: event['name'] }
           
          _setEventDatesOnLoad(eventObj, formItems.length);
          callbackEventsSelected(true);
  
        }
      }


      setAttendeeName(existingAttendeeName);
      setPhoneNumbers(existingPhoneNumbersArray);
      setChineseName(existingChineseNamesArray);
      setNextOfKinName(existingNextOfKinNamesArray);
      setNextOfKinPhoneNumbers(existingNextOfKinPhoneNumbersArray);
      setBirthDate(existingBirthDate);
      setPassportNumber(existingPassportNumber);
      setPassportIssueDate(existingPassportIssueDate);
      setPassportExpiryDate(existingPassportExpiryDate);
      setImageFiles(imageFileArray);
      setConsent(existingConsent);
      setRemark(existingRemark);


      callbackName(existingAttendeeName);
      callbackBirthDate(existingBirthDate);
      callbackPhoneNumber(existingPhoneNumbersArray);
      callbackNextOfKinName(existingNextOfKinNamesArray);
      callbackNextOfKinPhoneNumber(existingNextOfKinPhoneNumbersArray);
      callbackPassportNumber(existingPassportNumber);
      callbackPassportExpiryDate(existingPassportExpiryDate);
      callbackPassportIssuedDate(existingPassportIssueDate);
      callbackConsent(existingConsent);
      callbackRemarks(existingRemark);

      _checkNamesEntered(existingAttendeeName);
      _checkFileUploaded(imageFileArray);
      _checkPhoneNumberEntered(existingPhoneNumbersArray);
      _checkPassportNumberEntered(existingPassportNumber);
      _checkDateEntered(existingBirthDate);
      _checkPassportExpiryDateEntered(existingPassportExpiryDate);
      _checkPassportIssueDateEntered(existingPassportIssueDate);

     
    } else {
      existingDonationAmount.push(minDonationString);
      existingCustomPriceAmount.push(minDonationvalue);
      existingInputValue.push('');
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
      existingBirthDate.push('');
      existingPassportNumber.push('');
      existingPassportExpiryDate.push('');
      existingPassportIssueDate.push('');
      imageFileArray.push('');
      existingConsent.push(1);
      existingRemark.push('');
    }

    //setDonationAmount(existingDonationAmount);
    if (userValid && existingAttendeeName.length <= 0) {

      existingAttendeeName.push(userInfo.firstname + ' ' + userInfo.lastname);
      existingChineseNamesArray.push(userInfo.gmb_fullnamechinese);
      existingPhoneNumbersArray.push(userInfo.mobilephone)
    }

    setAttendeeName(existingAttendeeName);
    setCustomPrice(existingCustomPriceAmount);
    setInputValue(existingInputValue);
    setMultiplers(multipliersArray);
    setPhoneNumbers(existingPhoneNumbersArray);
    setChineseName(existingChineseNamesArray);
    setNextOfKinName(existingNextOfKinNamesArray);
    setNextOfKinPhoneNumbers(existingNextOfKinPhoneNumbersArray);
    setBirthDate(existingBirthDate);
    setPassportNumber(existingPassportNumber);
    setPassportIssueDate(existingPassportIssueDate);
    setPassportExpiryDate(existingPassportExpiryDate);
    setImageFiles(imageFileArray);
    setConsent(existingConsent);
    setRemark(existingRemark);

    callbackMultiplier(multipliersArray);
    //callbackEvent(eventNamesArray);
   //callbackEventID(eventIDsArray);
    callbackBirthDate(existingBirthDate);
    callbackConsent(existingConsent);
    callbackRemarks(existingRemark);

    //_checkEventsEntered(eventNamesArray);
    
    callbackPrice(existingCustomPriceAmount);
    callbackName(existingAttendeeName);
    callbackPhoneNumber(existingPhoneNumbersArray);
    callbackNextOfKinName(existingNextOfKinNamesArray);
    callbackNextOfKinPhoneNumber(existingNextOfKinPhoneNumbersArray);
    callbackPassportNumber(existingPassportNumber);
    callbackPassportExpiryDate(existingPassportExpiryDate);
    callbackPassportIssuedDate(existingPassportIssueDate);
     
    _checkDateEntered(existingBirthDate);
    _checkPricesEntered(existingCustomPriceAmount);
    _checkNamesEntered(existingAttendeeName);
    _checkFileUploaded(imageFileArray);
    _checkPhoneNumberEntered(existingPhoneNumbersArray);
    _checkPassportNumberEntered(existingPassportNumber);
    _checkPassportExpiryDateEntered(existingPassportExpiryDate);
    _checkPassportIssueDateEntered(existingPassportIssueDate);

    if(productEvents.length === 1 && formItems.length <= 0)
    {
      
    const event = productEvents[0];
    const eventObj = { value: event['id'], label: event['name'] }
     
    _setEventDates(eventObj, 0);
    callbackEventsSelected(true);

    }
    else if(productEvents.length > 1){
      setSelectValue(eventNamesArray);
      setCoursesEventId(eventIDsArray);
      callbackEvent(eventNamesArray);
      callbackEventID(eventIDsArray);
      _checkEventsEntered(eventNamesArray);
    }


  }, [formItems])

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (selectValue.length > 0) {
      const selectValueArray = selectValue.slice();
      const eventIDArray = coursesEventId.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _checkEventsEntered = (cousesEventArray: string[]) => {
    let eventsSelected = cousesEventArray.length > 0 ? true : false;

    for (let i = 0; i < cousesEventArray.length; i++) {
      if (cousesEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false;
        break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _setRemarksInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 100;

    _setRemarkArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setRemarkArray = (name: string, index: number) => {
    const remarkArray = remark.slice();

    while (typeof remarkArray[`${index}`] === 'undefined') {
      remarkArray.push('');
    }

    remarkArray[`${index}`] = name;

    remarkArray[`${index}`].trim();

    setRemark(remarkArray);

    callbackRemarks(remarkArray);
  }

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';


      inputEventDates += event.label;
      inputEventIDs += event.value;


    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);

    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(1, index);

    _checkEventsEntered(selectValueArray);
  }

  const _setEventDatesOnLoad = (event: any, formLength: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';


    inputEventDates += event.label;
    inputEventIDs += event.value;


    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    for(let i=0; i< formLength; i++)
    {
      
    while (typeof selectValueArray[`${i}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${i}`] === 'undefined') {
      eventIDArray.push('');
    }
    selectValueArray[`${i}`] = inputEventDates;
    eventIDArray[`${i}`] = inputEventIDs;

    }

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);
    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    //_setMultipler(1, index);

  }

  const _setEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';


      inputEventDates += event.label;
      inputEventIDs += event.value;


    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);

    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(1, index);

  }

  const _buildBirthDate = (idx: number): JSX.Element | null => {
    if (typeof birthDate[`${idx}`] === 'undefined') {
      return null;
    }
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.birthDate}</p>
          <DatePicker
            onChange={(date: Date) => _setBirthDate(date, idx)}
            value={birthDate[`${idx}`] === '' || birthDate[`${idx}`].includes('1900') ? null : new Date(birthDate[`${idx}`])}
            format={'dd-MM-y'}
            maxDate={new Date()}
            className={'datepicker'}
            dayPlaceholder={''}
            monthPlaceholder={''}
            yearPlaceholder={''}
          />
        </div>
      </div>
    );
  }

  const _buildPassportExpiryDate = (idx: number): JSX.Element | null => {
    if (typeof passportExpiryDate[`${idx}`] === 'undefined') {
      return null;
    }
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.passportExpiryDate}</p>
          <DatePicker
            onChange={(date: Date) => _setPassportExpiryDate(date, idx)}
            value={passportExpiryDate[`${idx}`] === '' || passportExpiryDate[`${idx}`]?.includes('1900') ? null : new Date(passportExpiryDate[`${idx}`])}
            format={'dd-MM-y'}
            maxDate={new Date()}
            className={'datepicker'}
            dayPlaceholder={''}
            monthPlaceholder={''}
            yearPlaceholder={''}
          />
        </div>
      </div>
    );
  }

  const _buildPassportIssueDate = (idx: number): JSX.Element | null => {
    if (typeof passportIssueDate[`${idx}`] === 'undefined') {
      return null;
    }
    return (
      <div className='row'>
        <div className='col-lg-4 form-field'>
          <p className='form-label'>{formLabels.passportIssueDate}</p>
          <DatePicker
            onChange={(date: Date) => _setPassportIssueDate(date, idx)}
            value={passportIssueDate[`${idx}`] === '' || passportIssueDate[`${idx}`]?.includes('1900') ? null : new Date(passportIssueDate[`${idx}`])}
            format={'dd-MM-y'}
            maxDate={new Date()}
            className={'datepicker'}
            dayPlaceholder={''}
            monthPlaceholder={''}
            yearPlaceholder={''}
          />
        </div>
      </div>
    );
  }
  const _buildEventDropdown = (idx: number): JSX.Element => {
    if(productEvents.length > 1)
    {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{formLabels.coursesEvents}</p>
            <Select
              value={_displayEventDates(idx)}
              closeMenuOnSelect={true}
              options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
              onChange={event => _setMultipleEventDates(event, idx)}
              classNamePrefix='eventSelect'
              className='select'
              noOptionsMessage={() => null}
              blurInputOnSelect={false}
            />
          </div>
        </div>
      );

    }
    const event = productEvents[0];
  
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.coursesEvents}</p>
          <Select
            defaultValue={{value: event['id'], label: event['name']}}
            closeMenuOnSelect={true}
            onChange={event => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
   
  }

  const _buildUploadPassport = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <p className='form-label col-12'>{formLabels.passportUpload}</p>
        <div className='col-lg-12 row form-field'>
          <div className='col-lg-6  form-field'>
            <input
              type='file'
              name='uploadFile'
              inputMode='text'
              aria-live='polite'
              role='spinbutton'
              accept=".jpeg, .png, .jpg"
              onChange={(event) => _setFileInputValue(event, idx)}
              className='form-control'
            />
          </div>
          <div className='ecomm-purchase-form-upload col-lg-3 offset-lg-1 form-field'>
            <button type='submit' disabled={passportNumber[`${idx}`] && isValidFileSize ? false : true} onClick={(e: any) => _uploadFileToAzure(idx)}>{resource.uploadFile[`${localeCode}`]}</button>
          </div>
        </div>
      </div>
    );
  }

  const _buildName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.attendeeName}</p>
          <input
            type='text'
            value={attendeeName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildNextOfKinName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.nameNextOfKin}</p>
          <input
            type='text'
            value={nextOfKinName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setNextOfKinNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildNextOfKinContactNumber = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.phoneNumberNextOfKin}</p>
          <input
            type='number'
            inputMode='tel'
            value={nextOfKinPhoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _setNextOfKinPhoneNumbers(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  const _buildPassportNumber = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.passportNumber}</p>
          <input
            type='text'
            inputMode='tel'
            value={passportNumber[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _setPassportNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  const _buildContactNumber = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.phoneNumber}</p>
          <input
            type='number'
            inputMode='tel'
            value={phoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _setPhoneNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  const _buildRemarks = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.remarks}</p>
          <textarea
            inputMode='text'
            value={remark[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setRemarksInputValue(event, idx)}
            className='form-control'
            maxLength={config.charactersLimitForRemarks ? config.charactersLimitForRemarks : 101}
          />
        </div>
      </div>
    );
  }



  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return <div className='form-record'>
              <div key={`row${i.id}`} className='form-details row'>
                {formScheme.map((element: IField, index: number) => {

                  if (element.field_name === 'attendeeName') {
                    return (
                      <div className='form-details-item col-lg-9'>
                        {remainingSlots !== 0 && <p className='form-label col-12'>{formLabels.availableSlots}{remainingSlots}</p>}
                        {_buildEventDropdown(idx)}
                        {_buildName(idx)}
                        {_buildContactNumber(idx)}
                        {_buildNextOfKinName(idx)}
                        {_buildNextOfKinContactNumber(idx)}
                        {_buildBirthDate(idx)}
                        {_buildPassportNumber(idx)}
                        {_buildPassportExpiryDate(idx)}
                        {_buildPassportIssueDate(idx)}
                        {_buildUploadPassport(idx)}
                        {showRemarksField && _buildRemarks(idx)}
                      </div>
                    );
                  }

                  if (!isMobile && element.field_type === 'product_price') {
                    return (
                      <div className='form-details-item bordered centred col-2'>
                        <p key={index} className='subtotal'>
                          ${selectedProduct.result?.Price! === 0 ? typeof customPrice[`${idx}`] !== 'undefined' ? customPrice[`${idx}`] : '0' : selectedProduct.result?.Price!}
                        </p>
                      </div>
                    );
                  }

                  if (element.field_type === 'action') {
                    if (isMobile) {
                      const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                      return (
                        <div className={style}>
                          {!isEditMode && allowMultipleRegistrations && isFileUploaded && showAddMoreButton && <div className='col-10'>
                            <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                          </div>}
                          <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                        </div>
                      );
                    }

                    return (
                      <div className='form-details-item centred col-1'>
                        <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                      </div>
                    );
                  }

                  return;
                })}
              </div>
            </div>;
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations && isFileUploaded && showAddMoreButton &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default EventPassportUploadForm;