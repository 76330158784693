import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

import Select from 'react-select'

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackPrice?: any;
  callbackName?: any;
  callbackEmailId?:any;
  callbackContactNumber?:any;
  callbackEventID?:any;
  callbackEvent?: any;
  callbackNamesEntered?: any;
  callbackPricesEntered?: any;
  callbackMultiplier: any;
  callbackEventsSelected?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  userInfo: any;
  productEvents: any[];
}

const CoursesForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackPrice, callbackName, callbackNamesEntered, callbackPricesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, userInfo,productEvents,callbackEventID,callbackEvent , callbackMultiplier, callbackEventsSelected}) => {

  const [customPrice, setCustomPrice] = useState([0]);
  const [inputValue, setInputValue] = useState(['']);
  const [attendeeName, setAttendeeName] = useState(['']);
  const [coursesEventId, setCoursesEventId] = useState(['']);
  const [selectValue, setSelectValue] = useState(['']);
  
  const [multipliers, setMultiplers] = useState([1]);

 


  const formLabels = resource.coursesForm[`${localeCode}`];

  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();

//   const _onChangePriceValue = (event: any, index: number) => {
//    // _setRadioValueArray(event.target.value, index);

//     if (event.target.value === resource.otherLabel[`${localeCode}`]) {
//       _setInputValueArray('0', index);
//       _setCustomPriceArray(0, index);
//     } else {
//       _setCustomPriceArray(Number(event.target.value), index);
//       _setInputValueArray('', index);
//     }
//   };

//   const _setCustomPriceArray = (price: number, index: number) => {
//     const customPriceArray = customPrice.slice();

//     while (typeof customPriceArray[`${index}`] === 'undefined') {
//       customPriceArray.push(0);
//     }

//     customPriceArray[`${index}`] = price;

//     setCustomPrice(customPriceArray);

//     callbackPrice(customPriceArray);

//     _checkPricesEntered(customPriceArray);
//   };

//   const _setRadioValueArray = (value: string, index: number) => {
//     const donationValueArray = donationAmount.slice();

//     while (typeof donationValueArray[`${index}`] === 'undefined') {
//       donationValueArray.push('');
//     }

//     donationValueArray[`${index}`] = value;

//     setDonationAmount(donationValueArray);
//   };

//   const _setInputValue = (event: any, index: number) => {
//     if (isNumeric(event.target.value)) {
//       _setInputValueArray(event.target.value, index);
//      // _setRadioValueArray(resource.otherLabel[`${localeCode}`], index);
//       _setCustomPriceArray(Number(event.target.value), index);
//     }
//   };

//   const _setInputValueArray = (value: string, index: number) => {
//     const inputValueArray = inputValue.slice();

//     while (typeof inputValueArray[`${index}`] === 'undefined') {
//       inputValueArray.push('');
//     }

//     inputValueArray[`${index}`] = value;

//     inputValueArray[`${index}`].trim();

//     setInputValue(inputValueArray);
//   };

  const _setNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setAttendeeNameArray(event.target.value.substring(0, maxLength - 1), index);
    //let eventObj = productEvents[0];
   
   // _setEventIdArray(eventObj['id'], 0);
     
  };

  // const _setEventIdArray = (eventId: string, index: number) => {
  //   const coursesEventIDArray = coursesEventId.slice();

  //   while (typeof coursesEventIDArray[`${index}`] === 'undefined') {
  //     coursesEventIDArray.push('');
  //   }

  //   coursesEventIDArray[`${index}`] = eventId;

  //   coursesEventIDArray[`${index}`].trim();

  //   setCoursesEventId(coursesEventIDArray);

   

  //   callbackEventID(coursesEventIDArray);
  // };

  const _setAttendeeNameArray = (name: string, index: number) => {
    const attendeeNameArray = attendeeName.slice();

    while (typeof attendeeNameArray[`${index}`] === 'undefined') {
      attendeeNameArray.push('');
    }

    attendeeNameArray[`${index}`] = name;

    attendeeNameArray[`${index}`].trim();

    setAttendeeName(attendeeNameArray);

    callbackName(attendeeNameArray);

    _checkNamesEntered(attendeeNameArray);
  };

 
  //const isNumeric = (num: any): boolean => { return !isNaN(num); };

  const _checkNamesEntered = (attendeeNameArray: string[]) => {
    let namesEntered = true;

    for (let i = 0; i < attendeeNameArray.length; i++) {
      if (attendeeNameArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkPricesEntered = (customPriceArray: number[]) => {
    let pricesEntered = true;

    for (let i = 0; i < customPriceArray.length; i++) {
      if (customPriceArray[`${i}`] == 0) {
        pricesEntered = false;
        break;
      }
    }

    callbackPricesEntered(pricesEntered);
  }



  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const element = formScheme.find(element => element.field_name === 'amount');

    const customPriceArray = customPrice.slice();
    const inputValueArray = inputValue.slice();
    const attendeeNameArray = attendeeName.slice();
    const multiplierArray = multipliers.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
 
    
    if (typeof customPriceArray[`${index}`] !== 'undefined') {
      if (customPriceArray.length > 1) {
        customPriceArray.splice(index, 1);
      } else {
        customPriceArray[0] = parseInt(element!.field_options![0]);
      }
    }

    if (typeof inputValueArray[`${index}`] !== 'undefined') {
      if (inputValueArray.length > 1) {
        inputValueArray.splice(index, 1);
      } else {
        inputValueArray[0] = '';
      }
    }

    if (typeof attendeeNameArray[`${index}`] !== 'undefined') {
      if (attendeeNameArray.length > 1) {
        attendeeNameArray.splice(index, 1);
      } else {
        attendeeNameArray[0] = '';
      }
    }
    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = 1;
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof coursesEventArray[`${index}`] !== 'undefined') {
      if (coursesEventArray.length > 1) {
        coursesEventArray.splice(index, 1);
      } else {
        coursesEventArray[0] = '';
      }
    }

    if (typeof coursesEventIDArray[`${index}`] !== 'undefined') {
      if (coursesEventIDArray.length > 1) {
        coursesEventIDArray.splice(index, 1);
      } else {
        coursesEventIDArray[0] = '';
      }
    }
   

    

    setCustomPrice(customPriceArray);
    setInputValue(inputValueArray);
    setAttendeeName(attendeeNameArray);
    setSelectValue(coursesEventArray);
    setCoursesEventId(coursesEventIDArray);


    callbackEvent(coursesEventArray);
    callbackEventID(coursesEventIDArray);
    callbackName(attendeeNameArray);
    callbackPrice(customPriceArray);

    _checkEventsEntered(coursesEventArray);
    _checkNamesEntered(attendeeNameArray);
    _checkPricesEntered(customPriceArray);
  }

  useEffect(() => {
    const element = formScheme.find(element => element.field_name === 'amount');

    var minDonationvalue = Number(element?.field_options![0]);
    var minDonationString = String(element?.field_options![0]);

    const existingCustomPriceAmount: number[] = [];
    const existingInputValue: string[] = [];
    const existingAttendeeName: string[] = [];
    const existingDonationAmount: string[] = [];
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        var customPriceAmount: number = formItems[`${i}`]['customPriceAmount'];

        const eventName: string = formItems[`${i}`]['eventName'];

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);

        switch (customPriceAmount) {
          case 0:
            customPriceAmount = Number(minDonationvalue);
            existingDonationAmount.push(minDonationString);
            existingInputValue.push('');
            break;

          case 2:
            existingDonationAmount.push('2');
            existingInputValue.push('');
            break;

          case 5:
            existingDonationAmount.push('5');
            existingInputValue.push('');
            break;

          case 10:
            existingDonationAmount.push('10');
            existingInputValue.push('');
            break;

          case 50:
            existingDonationAmount.push('50');
            existingInputValue.push('');
            break;

          default:
            if (customPriceAmount) {
              existingDonationAmount.push(resource.otherLabel[`${localeCode}`]);
              existingInputValue.push(customPriceAmount.toString());
            } else {
              customPriceAmount = Number(minDonationvalue);
              existingDonationAmount.push(minDonationString);
              existingInputValue.push('');
            }
            break;
        }

         
        existingAttendeeName.push(formItems[`${i}`]['names'][0]);
        
        existingCustomPriceAmount.push(customPriceAmount);
      }
      

      setAttendeeName(existingAttendeeName);
    

      callbackName(existingAttendeeName);
     

      _checkNamesEntered(existingAttendeeName);
    } else {
      existingDonationAmount.push(minDonationString);
      existingCustomPriceAmount.push(minDonationvalue);
      existingInputValue.push('');
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
    }

    //setDonationAmount(existingDonationAmount);
    if(userValid && existingAttendeeName.length <=0)
    {
      
        existingAttendeeName.push(userInfo.firstname+' '+userInfo.lastname);
    }

    setAttendeeName(existingAttendeeName);
    setCustomPrice(existingCustomPriceAmount);
    setInputValue(existingInputValue);
    setMultiplers(multipliersArray);
    setSelectValue(eventNamesArray);
    setCoursesEventId(eventIDsArray);

    callbackMultiplier(multipliersArray);
    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);

    _checkEventsEntered(eventNamesArray);

    callbackPrice(existingCustomPriceAmount);
    callbackName(existingAttendeeName);

    _checkPricesEntered(existingCustomPriceAmount);
    _checkNamesEntered(existingAttendeeName);
  }, [formItems])

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (selectValue.length > 0) {
      const selectValueArray = selectValue.slice();
      const eventIDArray = coursesEventId.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _checkEventsEntered = (cousesEventArray: string[]) => {
    let eventsSelected = cousesEventArray.length > 0 ? true : false;

    for (let i = 0; i < cousesEventArray.length; i++) {
      if (cousesEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false;
        break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    inputEventDates += event.label;
    inputEventIDs += event.value;

    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(event.length, index);

    _checkEventsEntered(selectValueArray);
  }


  const _buildEventDropdown = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.eventDate}</p>
          <Select
            value={_displayEventDates(idx)}
            closeMenuOnSelect={false}
            options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
            onChange={event => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  }

  const _buildName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.attendeeName}</p>
          <input
            type='text'
            value={attendeeName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }



  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return <div className='form-record'>
              <div key={`row${i.id}`} className='form-details row'>
                {formScheme.map((element: IField, index: number) => {

                  if (element.field_name === 'attendeeName') {
                    return (
                      <div className='form-details-item col-lg-9'>
                        {_buildEventDropdown(idx)}
                        {_buildName(idx)}

                        {/* {_buildAmountField(element, idx)} */}
                      </div>
                    );
                  }

                  if (!isMobile && element.field_type === 'product_price') {
                    return (
                      <div className='form-details-item bordered centred col-2'>
                        <p key={index} className='subtotal'>
                          ${selectedProduct.result?.Price! === 0 ? typeof customPrice[`${idx}`] !== 'undefined' ? customPrice[`${idx}`] : '0' : selectedProduct.result?.Price!}
                        </p>
                      </div>
                    );
                  }

                  if (element.field_type === 'action') {
                    if (isMobile) {
                      const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                      return (
                        <div className={style}>
                          {/* {!isEditMode && <div className='col-10'>
                            <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                          </div>} */}
                          <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                        </div>
                      );
                    }

                    return (
                      <div className='form-details-item centred col-1'>
                        <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                      </div>
                    );
                  }

                  return;
                })}
              </div>
            </div>;
          })}
        </div>

        {/* {!isMobile && !isEditMode &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>} */}
      </form>
    </div>
  );
};

export default CoursesForm;