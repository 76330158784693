import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IEventDetails, IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

import Select from 'react-select'

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackPrice?: any;
  callbackName?: any;
  callbackEmailId?:any;
  callbackContactNumber?:any;
  callbackEventID?:any;
  callbackEvent?: any;
  callbackNamesEntered?: any;
  callbackPricesEntered?: any;
  callbackMultiplier: any;
  callbackEventsSelected?: any;
  callbackPhoneNumber: any;
  callbackNextOfKinName: any;
  callbackNextOfKinPhoneNumber: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  userInfo: any;
  productEvents: any[];
  callbackPhoneNumbersEntered: any;
  allowMultipleRegistrations: boolean;
  insuranceAmount: string;
  insuranceFeeLabel: string;
  callbackInsuranceIncluded: any;
  callbackInsuranceIncludedNum: any;
}

const EventNextOfKinNameForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackPrice, callbackName, callbackNamesEntered, callbackPricesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, userInfo,productEvents,callbackEventID,callbackEvent , callbackMultiplier, callbackEventsSelected,  callbackPhoneNumber, callbackNextOfKinName, callbackNextOfKinPhoneNumber, callbackPhoneNumbersEntered, allowMultipleRegistrations, insuranceAmount, insuranceFeeLabel, callbackInsuranceIncluded, callbackInsuranceIncludedNum}) => {

  var insuranceAmountValue = Number(insuranceAmount);
  const [customPrice, setCustomPrice] = useState([insuranceAmountValue]);
  const [inputValue, setInputValue] = useState(['']);
  const [attendeeName, setAttendeeName] = useState(['']);
  const [coursesEventId, setCoursesEventId] = useState(['']);
  const [selectValue, setSelectValue] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [nextOfKinName, setNextOfKinName] = useState(['']);
  const [nextOfKinPhoneNumbers, setNextOfKinPhoneNumbers] = useState(['']);
  
  const [multipliers, setMultiplers] = useState([1]);
  const[showAddMoreButton, setShowAddMoreButton] = useState(false);
  const[remainingSlots, setRemainingSlots] = useState(0);

  const [isInsuranceIncluded, setIsInsuranceIncluded] = useState([false]);
  const [isInsuranceIncludedNum, setIsInsuranceIncludedNum] = useState([0]);
 


  const formLabels = resource.eventsNextOfKinNameForm[`${localeCode}`];

  const userValid = (userInfo && userInfo.emailaddress1) ? userInfo.emailaddress1.trim() !== '' : false;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();

//   const _onChangePriceValue = (event: any, index: number) => {
//    // _setRadioValueArray(event.target.value, index);

//     if (event.target.value === resource.otherLabel[`${localeCode}`]) {
//       _setInputValueArray('0', index);
//       _setCustomPriceArray(0, index);
//     } else {
//       _setCustomPriceArray(Number(event.target.value), index);
//       _setInputValueArray('', index);
//     }
//   };

//   const _setCustomPriceArray = (price: number, index: number) => {
//     const customPriceArray = customPrice.slice();

//     while (typeof customPriceArray[`${index}`] === 'undefined') {
//       customPriceArray.push(0);
//     }

//     customPriceArray[`${index}`] = price;

//     setCustomPrice(customPriceArray);

//     callbackPrice(customPriceArray);

//     _checkPricesEntered(customPriceArray);
//   };

//   const _setRadioValueArray = (value: string, index: number) => {
//     const donationValueArray = donationAmount.slice();

//     while (typeof donationValueArray[`${index}`] === 'undefined') {
//       donationValueArray.push('');
//     }

//     donationValueArray[`${index}`] = value;

//     setDonationAmount(donationValueArray);
//   };

//   const _setInputValue = (event: any, index: number) => {
//     if (isNumeric(event.target.value)) {
//       _setInputValueArray(event.target.value, index);
//      // _setRadioValueArray(resource.otherLabel[`${localeCode}`], index);
//       _setCustomPriceArray(Number(event.target.value), index);
//     }
//   };

//   const _setInputValueArray = (value: string, index: number) => {
//     const inputValueArray = inputValue.slice();

//     while (typeof inputValueArray[`${index}`] === 'undefined') {
//       inputValueArray.push('');
//     }

//     inputValueArray[`${index}`] = value;

//     inputValueArray[`${index}`].trim();

//     setInputValue(inputValueArray);
//   };

  const _setNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setAttendeeNameArray(event.target.value.substring(0, maxLength - 1), index);
    //let eventObj = productEvents[0];
   
   // _setEventIdArray(eventObj['id'], 0);
     
  };

  const _setNextOfKinNameInputValue = (event: any, index: number) => {
    let maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setNextOfKinNameArray(event.target.value.substring(0, maxLength - 1), index);
    //let eventObj = productEvents[0];
   
   // _setEventIdArray(eventObj['id'], 0);
     
  };


  // const _setEventIdArray = (eventId: string, index: number) => {
  //   const coursesEventIDArray = coursesEventId.slice();

  //   while (typeof coursesEventIDArray[`${index}`] === 'undefined') {
  //     coursesEventIDArray.push('');
  //   }

  //   coursesEventIDArray[`${index}`] = eventId;

  //   coursesEventIDArray[`${index}`].trim();

  //   setCoursesEventId(coursesEventIDArray);

   

  //   callbackEventID(coursesEventIDArray);
  // };

  const _setAttendeeNameArray = (name: string, index: number) => {
    const attendeeNameArray = attendeeName.slice();

    while (typeof attendeeNameArray[`${index}`] === 'undefined') {
      attendeeNameArray.push('');
    }

    attendeeNameArray[`${index}`] = name;

    attendeeNameArray[`${index}`].trim();

    setAttendeeName(attendeeNameArray);

    callbackName(attendeeNameArray);

    _checkNamesEntered(attendeeNameArray);
  };

  const _setPhoneNumber = (value: string, index: number) => {
    const valueArray = phoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setPhoneNumbers(valueArray);

    callbackPhoneNumber(valueArray);

    _checkPhoneNumberEntered(valueArray);
  }

  const _setNextOfKinNameArray = (name: string, index: number) => {
    const nextOfKinNameArray = nextOfKinName.slice();

    while (typeof nextOfKinNameArray[`${index}`] === 'undefined') {
        nextOfKinNameArray.push('');
    }

    nextOfKinNameArray[`${index}`] = name;

    nextOfKinNameArray[`${index}`].trim();

    setNextOfKinName(nextOfKinNameArray);

    callbackNextOfKinName(nextOfKinNameArray);

  };

  const _setNextOfKinPhoneNumbers = (value: string, index: number) => {
    const valueArray = nextOfKinPhoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setNextOfKinPhoneNumbers(valueArray);

    callbackNextOfKinPhoneNumber(valueArray);
  }

 
  //const isNumeric = (num: any): boolean => { return !isNaN(num); };

  const _checkNamesEntered = (attendeeNameArray: string[]) => {
    let namesEntered = attendeeNameArray.length > 0? true: false;

    for (let i = 0; i < attendeeNameArray.length; i++) {
      if (attendeeNameArray[`${i}`].trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkPhoneNumberEntered = (phoneNumbersArray: string[]) => {
    let phoneNumbersEntered = phoneNumbersArray.length > 0? true: false;

    for (let i = 0; i < phoneNumbersArray.length; i++) {
      if (phoneNumbersArray[`${i}`].trim() === '') {
        phoneNumbersEntered = false;
        break;
      }
    }

    callbackPhoneNumbersEntered(phoneNumbersEntered);
  }

  const _checkPricesEntered = (customPriceArray: number[]) => {
    let pricesEntered = true;

    for (let i = 0; i < customPriceArray.length; i++) {
      if (customPriceArray[`${i}`] == 0) {
        pricesEntered = false;
        break;
      }
    }

    callbackPricesEntered(pricesEntered);
  }



  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _addItem = () => {
    addRow(1);

    const attendeeNameArray = attendeeName.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const nextOfKinNamesArray = nextOfKinName.slice();
    const nextOfKinPhoneNumbersArray = nextOfKinPhoneNumbers.slice();
    const customPriceArray = customPrice.slice();
    customPriceArray.push(selectedProduct.result?.Price!);
   
    setShowAddMoreButton(false);
    setRemainingSlots(0);
    setAttendeeName(attendeeNameArray);
    setSelectValue(coursesEventArray);
    setCoursesEventId(coursesEventIDArray);
    setPhoneNumbers(phoneNumbersArray);
    setNextOfKinName(nextOfKinNamesArray);
    setNextOfKinPhoneNumbers(nextOfKinPhoneNumbersArray);
    setCustomPrice(customPriceArray);

    callbackEvent(coursesEventArray);
    callbackEventID(coursesEventIDArray);
    callbackName(attendeeNameArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackNextOfKinName(nextOfKinNamesArray);
    callbackNextOfKinPhoneNumber(nextOfKinPhoneNumbersArray);
    callbackPrice(customPriceArray);

    callbackNamesEntered(false);
    callbackEventsSelected(false);

    _checkPricesEntered(customPriceArray);

    if (productEvents.length === 1) {

      const event = productEvents[0];
      const eventObj = { value: event['id'], label: event['name'] }
      _setEventDates(eventObj, coursesEventArray.length);
      callbackEventsSelected(true);

    }

  }

  const _updateStateData = (index: number) => {
    const customPriceArray = customPrice.slice();
    const inputValueArray = inputValue.slice();
    const attendeeNameArray = attendeeName.slice();
    const multiplierArray = multipliers.slice();
    const coursesEventArray = selectValue.slice();
    const coursesEventIDArray = coursesEventId.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const nextOfKinNamesArray = nextOfKinName.slice();
    const nextOfKinPhoneNumbersArray = nextOfKinPhoneNumbers.slice();
    const isInsuranceIncludedArray = isInsuranceIncluded.slice();
    const isInsuranceIncludedNumArray = isInsuranceIncludedNum.slice();
 
    
    if (typeof customPriceArray[`${index}`] !== 'undefined') {
      if (customPriceArray.length > 1) {
        customPriceArray.splice(index, 1);
      } else {
        customPriceArray[0] = insuranceAmountValue;
      }
    }

    if (typeof inputValueArray[`${index}`] !== 'undefined') {
      if (inputValueArray.length > 1) {
        inputValueArray.splice(index, 1);
      } else {
        inputValueArray[0] = '';
      }
    }

    if (typeof attendeeNameArray[`${index}`] !== 'undefined') {
      if (attendeeNameArray.length > 1) {
        attendeeNameArray.splice(index, 1);
      } else {
        attendeeNameArray[0] = '';
      }
    }
    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = 1;
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof coursesEventArray[`${index}`] !== 'undefined') {
      if (coursesEventArray.length > 1) {
        coursesEventArray.splice(index, 1);
      } else {
        coursesEventArray[0] = '';
      }
    }

    if (typeof coursesEventIDArray[`${index}`] !== 'undefined') {
      if (coursesEventIDArray.length > 1) {
        coursesEventIDArray.splice(index, 1);
      } else {
        coursesEventIDArray[0] = '';
      }
    }

    if (typeof phoneNumbersArray[`${index}`] !== 'undefined') {
        if (phoneNumbersArray.length > 1) {
            phoneNumbersArray.splice(index, 1);
        } else {
            phoneNumbersArray[0] = '';
        }
      }

    if (typeof nextOfKinNamesArray[`${index}`] !== 'undefined') {
        if (nextOfKinNamesArray.length > 1) {
            nextOfKinNamesArray.splice(index, 1);
        } else {
            nextOfKinNamesArray[0] = '';
        }
      }  

    if (typeof nextOfKinPhoneNumbersArray[`${index}`] !== 'undefined') {
        if (nextOfKinPhoneNumbersArray.length > 1) {
          nextOfKinPhoneNumbersArray.splice(index, 1);
        } else {
          nextOfKinPhoneNumbersArray[0] = '';
        }
      }

    if (typeof isInsuranceIncludedArray[`${index}`] !== 'undefined') {
      if (isInsuranceIncludedArray.length > 1) {
        isInsuranceIncludedArray.splice(index, 1);
      } else {
        isInsuranceIncludedArray[0] = false;
      }
    }

    if (typeof isInsuranceIncludedNumArray[`${index}`] !== 'undefined') {
      if (isInsuranceIncludedNumArray.length > 1) {
        isInsuranceIncludedNumArray.splice(index, 1);
      } else {
        isInsuranceIncludedNumArray[0] = 0;
      }
    }

    setCustomPrice(customPriceArray);
    setInputValue(inputValueArray);
    setAttendeeName(attendeeNameArray);
    setSelectValue(coursesEventArray);
    setCoursesEventId(coursesEventIDArray);
    setPhoneNumbers(phoneNumbersArray);
    setNextOfKinName(nextOfKinNamesArray);
    setNextOfKinPhoneNumbers(nextOfKinPhoneNumbersArray);
    setIsInsuranceIncluded(isInsuranceIncludedArray);
    setIsInsuranceIncludedNum(isInsuranceIncludedNumArray);


    callbackEvent(coursesEventArray);
    callbackEventID(coursesEventIDArray);
    callbackName(attendeeNameArray);
    callbackPrice(customPriceArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackNextOfKinName(nextOfKinNamesArray);
    callbackNextOfKinPhoneNumber(nextOfKinPhoneNumbersArray);
    callbackInsuranceIncluded(isInsuranceIncludedArray);
    callbackInsuranceIncludedNum(isInsuranceIncludedNumArray);

    _checkEventsEntered(coursesEventArray);
    _checkNamesEntered(attendeeNameArray);
    _checkPricesEntered(customPriceArray);
    _checkPhoneNumberEntered(phoneNumbersArray);
  }

  useEffect(() => {
    const existingInputValue: string[] = [];
    const existingAttendeeName: string[] = [];
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const existingPhoneNumbersArray: string[] = [];
    const existingNextOfKinNamesArray : string[]=[];
    const existingNextOfKinPhoneNumbersArray : string[]=[];
    const customPriceArray: number[] = [];
    const insuranceIncludeArray: boolean[] = [];
    const isInsuranceIncludedNumArray: number[]=[];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const eventName: string = formItems[`${i}`]['eventName'];
        const hasInsurance = formItems[`${i}`]['isInsuranceIncluded'] ? formItems[`${i}`]['isInsuranceIncluded'] : 0;

        const checkbox = document.getElementById('insurance_' + i) as HTMLInputElement | null;

        if (checkbox) {
          if(hasInsurance === 0)
          {
            checkbox.checked = false;
          }
          else
          {
            checkbox.checked = true;
          }
        }

        if (hasInsurance === 0) {
          customPriceArray.push(selectedProduct.result?.Price!);
        }
        else {
          customPriceArray.push(selectedProduct.result?.Price! + insuranceAmountValue);
        }

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        existingPhoneNumbersArray.push(formItems[`${i}`]['phoneNumber']);
        existingNextOfKinNamesArray.push(formItems[`${i}`]['nameNextKin']);
        existingNextOfKinPhoneNumbersArray.push(formItems[`${i}`]['contactNumberNextKin']);
        insuranceIncludeArray.push(hasInsurance === 0 ? false: true);
        isInsuranceIncludedNumArray.push(hasInsurance);
        existingAttendeeName.push(formItems[`${i}`]['names'][0]);
      }

      if (productEvents.length === 1) {
        const event = productEvents[0];
        const eventObj = { value: event['id'], label: event['name'] }

        _setEventDatesOnLoad(eventObj, formItems.length);
        callbackEventsSelected(true);

      }
      

      setAttendeeName(existingAttendeeName);
      setPhoneNumbers(existingPhoneNumbersArray);
      setNextOfKinName(existingNextOfKinNamesArray);
      setNextOfKinPhoneNumbers(existingNextOfKinPhoneNumbersArray);
    

      callbackName(existingAttendeeName);
      callbackPhoneNumber(existingPhoneNumbersArray);
     

      _checkNamesEntered(existingAttendeeName);
      _checkPhoneNumberEntered(existingPhoneNumbersArray);
    } else {
      existingInputValue.push('');
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
      existingNextOfKinNamesArray.push('');
      existingNextOfKinPhoneNumbersArray.push('');
      customPriceArray.push(selectedProduct.result?.Price!);
    }

    if(userValid && existingAttendeeName.length <=0)
    {
        existingAttendeeName.push(userInfo.firstname + ' ' + userInfo.lastname);
        existingPhoneNumbersArray.push(userInfo.mobilephone)
    }

    setAttendeeName(existingAttendeeName);
    setInputValue(existingInputValue);
    setMultiplers(multipliersArray);
    setPhoneNumbers(existingPhoneNumbersArray);
    setNextOfKinName(existingNextOfKinNamesArray);
    setNextOfKinPhoneNumbers(existingNextOfKinPhoneNumbersArray);
    setIsInsuranceIncluded(insuranceIncludeArray);
    setIsInsuranceIncludedNum(isInsuranceIncludedNumArray);
    setCustomPrice(customPriceArray);

    callbackMultiplier(multipliersArray);
    callbackPrice(customPriceArray);
    callbackName(existingAttendeeName);
    callbackPhoneNumber(existingPhoneNumbersArray);
    callbackNextOfKinName(existingNextOfKinNamesArray);
    callbackNextOfKinPhoneNumber(existingNextOfKinPhoneNumbersArray);

    _checkPricesEntered(customPriceArray);
    _checkNamesEntered(existingAttendeeName);
    _checkPhoneNumberEntered(existingPhoneNumbersArray);

    callbackInsuranceIncluded(insuranceIncludeArray);
    callbackInsuranceIncludedNum(isInsuranceIncludedNumArray);

    if (productEvents.length === 1 && formItems.length <= 0) {

      const event = productEvents[0];
      const eventObj = { value: event['id'], label: event['name'] }

      _setEventDates(eventObj, 0);
      callbackEventsSelected(true);

    }
    else if(productEvents.length > 1){
      setSelectValue(eventNamesArray);
      setCoursesEventId(eventIDsArray);
      callbackEvent(eventNamesArray);
      callbackEventID(eventIDsArray);
      _checkEventsEntered(eventNamesArray);
    }

    
  }, [formItems])

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (selectValue.length > 0) {
      const selectValueArray = selectValue.slice();
      const eventIDArray = coursesEventId.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _checkEventsEntered = (cousesEventArray: string[]) => {
    let eventsSelected = cousesEventArray.length > 0 ? true : false;

    for (let i = 0; i < cousesEventArray.length; i++) {
      if (cousesEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false;
        break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    
      inputEventDates += event.label;
      inputEventIDs += event.value;
    

    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);

    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(event.length, index);

    _checkEventsEntered(selectValueArray);
  }

  const _setEventDatesOnLoad = (event: any, formLength: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';


    inputEventDates += event.label;
    inputEventIDs += event.value;


    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    for(let i=0; i< formLength; i++)
    {
      
    while (typeof selectValueArray[`${i}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${i}`] === 'undefined') {
      eventIDArray.push('');
    }
    selectValueArray[`${i}`] = inputEventDates;
    eventIDArray[`${i}`] = inputEventIDs;

    }

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);
    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    //_setMultipler(1, index);

  }

  const _setEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';


    inputEventDates += event.label;
    inputEventIDs += event.value;


    const selectValueArray = selectValue.slice();
    const eventIDArray = coursesEventId.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    let eventDetails: IEventDetails = productEvents.find(product => product['id'] == event.value);

    setShowAddMoreButton(!eventDetails.hideAddMoreButton);
    setRemainingSlots(eventDetails.eventAvailableSlot);

    setSelectValue(selectValueArray);
    setCoursesEventId(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(1, index);

  }

  const _setInsuranceAmount = (value: boolean, index: number) => {
    const valueArray = isInsuranceIncluded.slice();
    const insuranceArray = customPrice.slice();
    const insuranceNumArray = isInsuranceIncludedNum.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(false);
    }

    while (typeof insuranceArray[`${index}`] === 'undefined') {
      insuranceArray.push(0);
    }

    valueArray[`${index}`] = value;
    insuranceNumArray[`${index}`] = value === true? 1: 0;

    if (value === true) {
      insuranceArray[`${index}`] = selectedProduct.result?.Price! + insuranceAmountValue;

    }
    else {
      insuranceArray[`${index}`] = selectedProduct.result?.Price!;

    }


    setIsInsuranceIncluded(valueArray);
    setIsInsuranceIncludedNum(insuranceNumArray);
    setCustomPrice(insuranceArray);
    callbackPrice(insuranceArray);
    callbackInsuranceIncluded(valueArray);
    callbackInsuranceIncludedNum(insuranceNumArray);
  };


  const _buildEventDropdown = (idx: number): JSX.Element => {
    if (productEvents.length > 1) {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{formLabels.events}</p>
            <Select
              value={_displayEventDates(idx)}
              closeMenuOnSelect={true}
              options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
              onChange={event => _setMultipleEventDates(event, idx)}
              classNamePrefix='eventSelect'
              className='select'
              noOptionsMessage={() => null}
              blurInputOnSelect={false}
            />
          </div>
        </div>
      );

    }

    const event = productEvents[0];

    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.events}</p>
          <Select
            defaultValue={{ value: event['id'], label: event['name'] }}
            closeMenuOnSelect={true}
            onChange={event => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  }

  const _buildName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.name}</p>
          <input
            type='text'
            value={attendeeName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildNextOfKinName = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label col-12'>{formLabels.nameNextOfKin}</p>
          <input
            type='text'
            value={nextOfKinName[`${idx}`]}
            inputMode='text'
            aria-live='polite'
            role='spinbutton'
            onChange={(event) => _setNextOfKinNameInputValue(event, idx)}
            onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
          />
        </div>
      </div>
    );
  }

  const _buildContactNumber = ( idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.phoneNumber}</p>
          <input
            type='number'
            inputMode='tel'
            value={phoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _setPhoneNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  const _buildNextOfKinContactNumber = ( idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.phoneNumberNextOfKin}</p>
          <input
            type='number'
            inputMode='tel'
            value={nextOfKinPhoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _setNextOfKinPhoneNumbers(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete="none"
          />
        </div>
      </div>
    );
  }

  const _buildInsuranceField = (idx: number): JSX.Element | null => {
    if (typeof customPrice[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-lg-9 form-field'>
          <p className='form-label'>{formLabels.tax}</p>
          <p className='prompt'>{formLabels.taxPrompt}</p>
          <div>
            <label htmlFor={'insurance_' + idx} className='checkbox-option'>
              <input type='checkbox' id={'insurance_' + idx} onChange={event => _setInsuranceAmount(event.target.checked, idx)} />
              {insuranceFeeLabel + " +$" + insuranceAmount}
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return <div className='form-record'>
              <div key={`row${i.id}`} className='form-details row'>
                {formScheme.map((element: IField, index: number) => {

                  if (element.field_name === 'name') {
                    return (
                      <div className='form-details-item col-lg-9'>
                        <p className='form-label col-12'>{formLabels.availableSlots}{remainingSlots}</p>
                        {_buildEventDropdown(idx)}
                        {_buildName(idx)}
                        {_buildContactNumber(idx)}
                        {_buildNextOfKinName(idx)}
                        {_buildNextOfKinContactNumber(idx)}
                        {insuranceAmountValue !== 0 && _buildInsuranceField(idx)}
                      </div>
                    );
                  }

                  if (!isMobile && element.field_type === 'product_price') {
                    return (
                      <div className='form-details-item bordered centred col-2'>
                        <p key={index} className='subtotal'>
                          ${isInsuranceIncluded[`${idx}`] ? typeof customPrice[`${idx}`] !== 'undefined' ? customPrice[`${idx}`] : '0' : selectedProduct.result?.Price!}
                        </p>
                      </div>
                    );
                  }

                  if (element.field_type === 'action') {
                    if (isMobile) {
                      const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                      return (
                        <div className={style}>
                           {!isEditMode && allowMultipleRegistrations && showAddMoreButton && <div className='col-10'>
                            <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                          </div>}
                          <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                        </div>
                      );
                    }

                    return (
                      <div className='form-details-item centred col-1'>
                        <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                      </div>
                    );
                  }

                  return;
                })}
              </div>
            </div>;
          })}
        </div>

         {!isMobile && !isEditMode && allowMultipleRegistrations && showAddMoreButton &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>} 
      </form>
    </div>
  );
};

export default EventNextOfKinNameForm;