import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import Select from 'react-select'

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import resource from '../resources/resource.json';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackMultiplier: any;
  callbackEvent: any;
  callbackEventID: any;
  callbackName: any;
  callbackEventsSelected: any;
  callbackNamesEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const CeremonyForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackMultiplier, callbackEvent, callbackEventID, callbackName, callbackEventsSelected, callbackNamesEntered,
  formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents, allowMultipleRegistrations }) => {
  const [eventNames, setEventNames] = useState(['']);
  const [eventIDs, setEventIDs] = useState(['']);
  const [names, setNames] = useState(['']);

  const [multipliers, setMultiplers] = useState([1]);

  const formLabels = resource.ceremonyForm[`${localeCode}`];
  const namesCount = formScheme.find(form => form.field_name === 'name')!.field_number!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  const { handleSubmit, reset } = useForm();

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = names[`${index}`];

    if (!inputMultipleName.includes(';')) {
      inputMultipleName += ';'.repeat(namesCount - 1);
    }

    const inputMutlipleNameSplit = inputMultipleName.split(';');

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(';');

    _setCeremonyNameArray(inputName, index);
  }

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = names.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setNames(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    for (let i = 0; i < event.length; ++i) {
      inputEventDates += event[`${i}`].label + (i != event.length - 1 ? ';' : '');
      inputEventIDs += event[`${i}`].value + (i != event.length - 1 ? ';' : '');
    }

    const selectValueArray = eventNames.slice();
    const eventIDArray = eventIDs.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    setEventNames(selectValueArray);
    setEventIDs(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(event.length, index);

    _checkEventsEntered(selectValueArray);
  }

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return '';
  }

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (eventNames.length > 0) {
      const selectValueArray = eventNames.slice();
      const eventIDArray = eventIDs.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _checkEventsEntered = (ceremonyEventArray: string[]) => {
    let eventsSelected = ceremonyEventArray.length > 0 ? true : false;

    for (let i = 0; i < ceremonyEventArray.length; i++) {
      if (ceremonyEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false; break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _checkNamesEntered = (ceremonyNameArray: string[]) => {
    let namesEntered = ceremonyNameArray.length > 0 ? true : false;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false;
        break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkNumberOfSelectedEvent = (index: number): number => {
    const count = eventNames.length > 0 && typeof eventNames[`${index}`] !== 'undefined' ? eventNames[`${index}`].split(';').length : 1;

    return count;
  }

  const _addItem = () => {
    addRow(1);

    const multipliersArray = multipliers.slice();
    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();
    const namesArray = names.slice();

    multipliersArray.push(1);
    eventNamesArray.push('');
    eventIDsArray.push('');
    namesArray.push('');

    setMultiplers(multipliersArray);
    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);
    setNames(namesArray);

    callbackMultiplier(multipliersArray);
    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackName(namesArray);

    callbackEventsSelected(false);
    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);

          formItems.splice(index, 1);
          
          _updateStateData(index);
        }

      } else {
        deleteRow(index);

        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const multipliersArray = multipliers.slice();
    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();
    const namesArray = names.slice();

    if (typeof multipliersArray[`${index}`] !== 'undefined') {
      if (multipliersArray.length > 1) {
        multipliersArray.splice(index, 1);
      } else {
        multipliersArray[0] = 1;
      }

      setMultiplers(multipliersArray);

      callbackMultiplier(multipliersArray);
    }

    if (typeof eventNamesArray[`${index}`] !== 'undefined') {
      if (eventNamesArray.length > 1) {
        eventNamesArray.splice(index, 1);
      } else {
        eventNamesArray[0] = '';
      }

      setEventNames(eventNamesArray);

      callbackEvent(eventNamesArray);
    }

    if (typeof eventIDsArray[`${index}`] !== 'undefined') {
      if (eventIDsArray.length > 1) {
        eventIDsArray.splice(index, 1);
      } else {
        eventIDsArray[0] = '';
      }

      setEventIDs(eventIDsArray);

      callbackEventID(eventIDsArray);
    }

    if (typeof namesArray[`${index}`] !== 'undefined') {
      if (namesArray.length > 1) {
        namesArray.splice(index, 1);
      } else {
        namesArray[0] = '';
      }

      setNames(namesArray);

      callbackName(namesArray);
    }

    _checkEventsEntered(eventNamesArray);
    _checkNamesEntered(namesArray);
  }

  useEffect(() => {
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const namesArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const itemNames: string[] = formItems[`${i}`]['names'];

        let itemName = '';

        itemNames.map((name, index) => {
          if (index !== 0 && index < namesCount) {
            itemName += ';' + name;
          } else {
            itemName += name;
          }
        });

        const eventName: string = formItems[`${i}`]['eventName'];

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        namesArray.push(itemName);
      }
      
      setNames(namesArray);
      
      callbackName(namesArray);

      _checkNamesEntered(namesArray);
    } else {
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
    }

    setMultiplers(multipliersArray);
    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);

    callbackMultiplier(multipliersArray);
    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);

    _checkEventsEntered(eventNamesArray);
  }, [formItems])

  const _buildEventDropdown = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.eventDate}</p>
          <Select
            isMulti
            value={_displayEventDates(idx)}
            closeMenuOnSelect={false}
            options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
            onChange={event => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  }

  const _buildNameGroup = (idx: number, namesCount: number): JSX.Element[] => {
    const nameLabel = formLabels.name;
    const namesArray = Array.apply(null, Array(namesCount)).map(function (x, i) { return i; });

    return namesArray.map(nameIndex => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{nameLabel.endsWith('*') ? (nameIndex === 0 ? nameLabel.substring(0, nameLabel.length - 1) + ' ' + (nameIndex + 1) + '*' : nameLabel.replace('*', ' ' + (nameIndex + 1))) : nameLabel + ' ' + nameIndex + 1}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayName(names[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={event => _setNameMultipleInputValue(event, idx, nameIndex)}
              onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_type === 'dropdown') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildEventDropdown(idx)}
                          {namesCount > 0 && _buildNameGroup(idx, namesCount)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price! * _checkNumberOfSelectedEvent(idx)}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  )
};

export default CeremonyForm;