import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { toast } from 'react-toastify';

import { useForm } from 'react-hook-form';

import countries from '../resources/countries.json';
import resource from '../resources/resource.json';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackMultiplier: any;
  callbackEvent: any;
  callbackEventID: any;
  callbackName: any;
  callbackHasTaxDeduction: any;
  callbackNRIC: any;
  callbackPhoneNumber: any;
  callbackBuilding: any;
  callbackUnitNumber: any;
  callbackPostalCode: any;
  callbackCountry: any;
  callbackEnableSaveDraft: any;
  callbackFormsValidation: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const EventTaxDeductionForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackMultiplier, callbackEvent, callbackEventID, callbackName, callbackHasTaxDeduction, callbackNRIC,
  callbackPhoneNumber, callbackBuilding, callbackUnitNumber, callbackPostalCode, callbackCountry, callbackEnableSaveDraft, callbackFormsValidation,
  formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents,allowMultipleRegistrations }) => {
  const [eventNames, setEventNames] = useState(['']);
  const [eventIDs, setEventIDs] = useState(['']);
  const [names, setNames] = useState(['']);
  const [taxDeductions, setTaxDeductions] = useState([false]);
  const [nrics, setNRICs] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [buildingNames, setBuildingNames] = useState(['']);
  const [unitNumbers, setUnitNumbers] = useState(['']);
  const [postalCodes, setPostalCodes] = useState(['']);
  const [countryNames, setCountryNames] = useState(['']);

  const [multipliers, setMultiplers] = useState([1]);

  const minIdentitySize = 9;
  const maxIdentitySize = 10;
  const formLabels = resource.eventTaxDeductionForm[`${localeCode}`];
  const namesCount = formScheme.find(form => form.field_name === 'name')!.field_number!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  };

  const _setEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    for (let i = 0; i < event.length; ++i) {
      inputEventDates += event[`${i}`].label + (i != event.length - 1 ? ';' : '');
      inputEventIDs += event[`${i}`].value + (i != event.length - 1 ? ';' : '');
    }

    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();

    while (typeof eventNamesArray[`${index}`] === 'undefined') {
      eventNamesArray.push('');
    }

    while (typeof eventIDsArray[`${index}`] === 'undefined') {
      eventIDsArray.push('');
    }

    eventNamesArray[`${index}`] = inputEventDates;
    eventIDsArray[`${index}`] = inputEventIDs;

    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);

    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);

    _setMultipler(event.length, index);

    _validateForms(eventNamesArray, eventIDsArray, names, taxDeductions, nrics);
  };

  const _formatNameInput = (event: any, index: number, nameIndex: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleNames = names[`${index}`];

    if (!inputMultipleNames.includes(';')) {
      inputMultipleNames += ';'.repeat(namesCount - 1);
    }

    const newValues = inputMultipleNames.split(';');

    newValues[`${nameIndex}`] = inputName;
    inputName = newValues.join(';');

    _setNames(inputName, index);
  };

  const _setNames = (name: string, index: number) => {
    const valueArray = names.slice();

    // To handle adding of a new form record.
    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = name;

    valueArray[`${index}`].trim();

    setNames(valueArray);

    callbackName(valueArray);

    _validateForms(eventNames, eventIDs, valueArray, taxDeductions, nrics);
  };

  const _setTaxDeduction = (value: boolean, index: number) => {
    const valueArray = taxDeductions.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(false);
    }

    valueArray[`${index}`] = value;

    setTaxDeductions(valueArray);

    callbackHasTaxDeduction(valueArray);

    // Clear NRIC/ FIN/ UEN and address fields if value is false.
    if (!value) {
      _resetFields(index);
    }

    _validateForms(eventNames, eventIDs, names, valueArray, nrics);
  };

  const _setNric = (value: string, index: number) => {
    const valueArray = nrics.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value.trim();

    setNRICs(valueArray);

    callbackNRIC(valueArray);

    const result = _validateIdentity(value);

    if ((value.length === minIdentitySize || value.length === maxIdentitySize) && !result) {
      toast.warning(resource.invalidNricFinUenMsg[`${localeCode}`]);
    }

    _validateForms(eventNames, eventIDs, names, taxDeductions, valueArray);
  };

  const _setPhoneNumbers = (value: string, index: number) => {
    const valueArray = phoneNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value;

    setPhoneNumbers(valueArray);

    callbackPhoneNumber(valueArray);
  };

  const _setBuildingName = (value: string, index: number) => {
    const valueArray = buildingNames.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value.trim();

    setBuildingNames(valueArray);

    callbackBuilding(valueArray);
  };

  const _setUnitNumber = (value: string, index: number) => {
    const valueArray = unitNumbers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value.trim();

    setUnitNumbers(valueArray);

    callbackUnitNumber(valueArray);
  };

  const _setPostalCode = (value: string, index: number) => {
    const valueArray = postalCodes.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    if(value.length<=6){
      valueArray[`${index}`] = value.trim();
    }else{
      toast.warning(resource.invalidPostalCodeMessage[`${localeCode}`]);
    }

    setPostalCodes(valueArray);

    callbackPostalCode(valueArray);
  };

  const _setCountryName = (event: any, index: number) => {
    const valueArray = countryNames.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = event.label;

    setCountryNames(valueArray);

    callbackCountry(valueArray);
  };

  const _resetFields = (index: number) => {
    const nricsArray = nrics.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();
    const countryNamesArray = countryNames.slice();

    nricsArray[`${index}`] = '';
    phoneNumbersArray[`${index}`] = '';
    buildingNamesArray[`${index}`] = '';
    unitNumbersArray[`${index}`] = '';
    postalCodesArray[`${index}`] = '';
    countryNamesArray[`${index}`] = '';

    setNRICs(nricsArray);
    setPhoneNumbers(phoneNumbersArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);
    setCountryNames(countryNamesArray);

    callbackNRIC(nricsArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackBuilding(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);
    callbackCountry(countryNamesArray);
  };

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (eventNames.length > 0) {
      const selectValueArray = eventNames.slice();
      const eventIDArray = eventIDs.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  };

  const _displayNames = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return '';
  };

  const _checkTaxDeductions = (taxDeductionsArray: boolean[], nricsArray: string[]): boolean => {
    let result = taxDeductionsArray.length > 0 ? true : false;

    for (let i = 0; i < taxDeductionsArray.length; ++i) {
      if (taxDeductionsArray[`${i}`]) {
        result = nricsArray[`${i}`] === '' ? false : _validateIdentity(nricsArray[`${i}`]);
      }

      if (!result) {
        break;
      }
    }

    return result;
  };

  const _checkStringArray = (valueArray: string[]): boolean => {
    let result = valueArray.length > 0 ? true : false;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        result = false; break;
      }
    }

    return result;
  };

  const _validateIdentity = (value: string): boolean => {
    return value.length === minIdentitySize || value.length === maxIdentitySize ? (_validateNricFin(value) ? true : _validateUen(value)) : false;
  };

  const _validateNricFin = (value: string): boolean => {
    // Validation rules.
    const nricRegex = /(\D)(\d{7})(\D)/;
    const nricTypeRegex = /S|T|F|G/;
    const weightArr = [2, 7, 6, 5, 4, 3, 2];
    const nricLetterST = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
    const nricLetterFG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];

    // Set nric to all uppercase to remove case sensitivity.
    const nric = value.toUpperCase();

    // Return false if it fails basic validation.
    if (!nricRegex.exec(nric)) {
      return false;
    }

    // Get nric type.
    const nricArr = nric.match(nricRegex);

    if (nricArr) {
      const nricType = nricArr[1];

      // Return false if it fails basic validation.
      if (!nricTypeRegex.exec(nricType)) {
        return false;
      }

      // Multiply each digit in the nric number by it's weight in order.
      const nricDigitsArr = nricArr[2].split('');

      let total = 0;

      for (let i = 0; i < nricDigitsArr.length; ++i) {
        total += parseInt(nricDigitsArr[`${i}`]) * weightArr[`${i}`];
      }

      // If the nric type is T or G, add 4 to the total.
      if (['T', 'G'].indexOf(nricType) >= 0) {
        total += 4;
      }

      const letterIndex = total % 11;
      const nricLetter = nricArr[3];

      // Check last letter of nric for Singaporeans: nricLetterST.
      // Check last letter of nric for foreigners : nricLetterFG.
      const result = ['S', 'T'].indexOf(nricType) >= 0 ? nricLetterST[`${letterIndex}`] === nricLetter :  nricLetterFG[`${letterIndex}`] === nricLetter;

      return result;
    }     

    return false;
  };

  const _validateUen = (value: string): boolean => {
    const checkAlphabet = /^[a-zA-Z]+$/;
    const entityTypeIndicator = [
      'LP', 'LL', 'FC', 'PF', 'RF', 'MQ', 'MM', 'NB', 'CC', 'CS', 'MB', 'FM', 'GS', 'GA',
      'GB', 'DP', 'CP', 'NR', 'CM', 'CD', 'MD', 'HS', 'VH', 'CH', 'MH', 'CL', 'XL', 'CX',
      'RP', 'TU', 'TC', 'FB', 'FN', 'PA', 'PB', 'SS', 'MC', 'SM'
    ];

    const uen = value.toUpperCase();
    
    const uenStrArray = uen.split('');

    // Businesses registered with ACRA.
    if (uenStrArray.length === minIdentitySize) {
      // Check that last character is a letter.
      if (!checkAlphabet.test(uenStrArray[`${uenStrArray.length - 1}`])) {        
        return false;
      }

      for (let i = 0; i < uenStrArray.length - 1; ++i) {
        // Check that first 8 letters are all numbers.
        if (checkAlphabet.test(uenStrArray[`${i}`])) {
          return false;
        }
      }

      return true;
    } else if (uenStrArray.length === maxIdentitySize) {
      // Check that last character is a letter.
      if (!checkAlphabet.test(uenStrArray[uenStrArray.length - 1])) {
        return false;
      }

      // Local companies registered with ACRA: if.
      // All other entities which will be issued new UEN: else.
      if (!checkAlphabet.test(uenStrArray[0]) && !checkAlphabet.test(uenStrArray[1]) && !checkAlphabet.test(uenStrArray[2]) && !checkAlphabet.test(uenStrArray[3])) {
        // Check that 5th to 9th letters are all numbers.
        return !checkAlphabet.test(uenStrArray[4]) && !checkAlphabet.test(uenStrArray[5]) && !checkAlphabet.test(uenStrArray[6]) && !checkAlphabet.test(uenStrArray[7]) && !checkAlphabet.test(uenStrArray[8]);
      } else {
        // Check that 1st letter is either T or S or R.
        if (uenStrArray[0] !== 'T' && uenStrArray[0] !== 'S' && uenStrArray[0] !== 'R') {
          return false;
        }

        // Check that 2nd and 3rd letters are numbers only.
        if (checkAlphabet.test(uenStrArray[1]) || checkAlphabet.test(uenStrArray[2])) {
          return false;
        }

        // Check that 4th letter is an alphabet.
        if (!checkAlphabet.test(uenStrArray[3])) {
          return false;
        }

        // Check entity-type indicator.
        const entityType = uenStrArray[3] + uenStrArray[4];

        let entityTypeMatch = false;

        for (let i = 0; i < entityTypeIndicator.length; ++i) {
          if (entityTypeIndicator[`${i}`] === entityType) {
            entityTypeMatch = true;
          }
        }

        if (!entityTypeMatch) {
          return false;
        }

        // Check that 6th to 9th letters are numbers only.
        if (checkAlphabet.test(uenStrArray[5]) || checkAlphabet.test(uenStrArray[6]) || checkAlphabet.test(uenStrArray[7]) || checkAlphabet.test(uenStrArray[8])) {
          return false;
        }

        return true;
      }
    }

    return false;
  };

  const _validateForms = (eventNamesArray: string[], eventIDsArray: string[], namesArray: string[], taxDeductionsArray: boolean[], nricsArray: string[]) => {
    const hasNames = _checkStringArray(namesArray);

    const result = hasNames && _checkStringArray(eventNamesArray) && _checkStringArray(eventIDsArray) && _checkTaxDeductions(taxDeductionsArray, nricsArray);

    callbackEnableSaveDraft(hasNames);

    callbackFormsValidation(result);
  };

  const _addItem = () => {
    addRow(1);

    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();
    const multipliersArray = multipliers.slice();
    const namesArray = names.slice();
    const taxDeductionsArray = taxDeductions.slice();
    const nricsArray = nrics.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();
    const countryNamesArray = countryNames.slice();

    eventNamesArray.push('');
    eventIDsArray.push('');
    multipliersArray.push(1);
    namesArray.push('');
    taxDeductionsArray.push(false);
    nricsArray.push('');
    phoneNumbersArray.push('');
    buildingNamesArray.push('');
    unitNumbersArray.push('');
    postalCodesArray.push('');
    countryNamesArray.push('');

    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);
    setMultiplers(multipliersArray);
    setNames(namesArray);
    setTaxDeductions(taxDeductionsArray);
    setNRICs(nricsArray);
    setPhoneNumbers(phoneNumbersArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);
    setCountryNames(countryNamesArray);

    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackMultiplier(multipliersArray);
    callbackName(namesArray);
    callbackHasTaxDeduction(taxDeductionsArray);
    callbackNRIC(nricsArray);
    callbackPhoneNumber(phoneNumbersArray);
    callbackBuilding(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);
    callbackCountry(countryNamesArray);

    _validateForms(eventNamesArray, eventIDsArray, namesArray, taxDeductionsArray, nricsArray);
  };

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);

          formItems.splice(index, 1);

          _updateStateData(index);
        }
      } else {
        deleteRow(index);

        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  };

  const _updateStateData = (index: number) => {
    const multipliersArray = multipliers.slice();
    const eventNamesArray = eventNames.slice();
    const eventIDsArray = eventIDs.slice();
    const namesArray = names.slice();
    const taxDeductionsArray = taxDeductions.slice(); 
    const nricsArray = nrics.slice();
    const phoneNumbersArray = phoneNumbers.slice();
    const buildingNamesArray = buildingNames.slice();
    const unitNumbersArray = unitNumbers.slice();
    const postalCodesArray = postalCodes.slice();
    const countryNamesArray = countryNames.slice();

    if (typeof multipliersArray[`${index}`] !== 'undefined') {
      if (multipliersArray.length > 1) {
        multipliersArray.splice(index, 1);
      } else {
        multipliersArray[0] = 1;
      }

      setMultiplers(multipliersArray);

      callbackMultiplier(multipliersArray);
    }

    if (typeof eventNamesArray[`${index}`] !== 'undefined') {
      if (eventNamesArray.length > 1) {
        eventNamesArray.splice(index, 1);
      } else {
        eventNamesArray[0] = '';
      }

      setEventNames(eventNamesArray);

      callbackEvent(eventNamesArray);
    }

    if (typeof eventIDsArray[`${index}`] !== 'undefined') {
      if (eventIDsArray.length > 1) {
        eventIDsArray.splice(index, 1);
      } else {
        eventIDsArray[0] = '';
      }

      setEventIDs(eventIDsArray);

      callbackEventID(eventIDsArray);
    }

    if (typeof namesArray[`${index}`] !== 'undefined') {
      if (namesArray.length > 1) {
        namesArray.splice(index, 1);
      } else {
        namesArray[0] = '';
      }

      setNames(namesArray);

      callbackName(namesArray);
    }

    if (typeof taxDeductionsArray[`${index}`] !== 'undefined') {
      if (taxDeductionsArray.length > 1) {
        taxDeductionsArray.splice(index, 1);
      } else {
        taxDeductionsArray[0] = false;

        const checkbox = document.getElementById('tax_0') as HTMLInputElement | null; 

        if (checkbox) {
          checkbox.checked = false;
        }
      }

      setTaxDeductions(taxDeductionsArray);

      callbackHasTaxDeduction(taxDeductionsArray);
    }

    if (typeof nricsArray[`${index}`] !== 'undefined') {
      if (nricsArray.length > 1) {
        nricsArray.splice(index, 1);
      } else {
        nricsArray[0] = '';
      }

      setNRICs(nricsArray);

      callbackNRIC(nricsArray);
    }

    if (typeof phoneNumbersArray[`${index}`] !== 'undefined') {
      if (phoneNumbersArray.length > 1) {
        phoneNumbersArray.splice(index, 1);
      } else {
        phoneNumbersArray[0] = '';
      }

      setPhoneNumbers(phoneNumbersArray);

      callbackPhoneNumber(phoneNumbersArray);
    }

    if (typeof buildingNamesArray[`${index}`] !== 'undefined') {
      if (buildingNamesArray.length > 1) {
        buildingNamesArray.splice(index, 1);
      } else {
        buildingNamesArray[0] = '';
      }

      setBuildingNames(buildingNamesArray);
    }

    if (typeof unitNumbersArray[`${index}`] !== 'undefined') {
      if (unitNumbersArray.length > 1) {
        unitNumbersArray.splice(index, 1);
      } else {
        unitNumbersArray[0] = '';
      }

      setUnitNumbers(unitNumbersArray);

      callbackUnitNumber(unitNumbersArray);
    }

    if (typeof postalCodesArray[`${index}`] !== 'undefined') {
      if (postalCodesArray.length > 1) {
        postalCodesArray.splice(index, 1);
      } else {
        postalCodesArray[0] = '';
      }

      setPostalCodes(postalCodesArray);

      callbackPostalCode(postalCodesArray);
    }

    if (typeof countryNamesArray[`${index}`] !== 'undefined') {
      if (countryNamesArray.length > 1) {
        countryNamesArray.splice(index, 1);
      } else {
        countryNamesArray[0] = '';
      }

      setCountryNames(countryNamesArray);

      callbackCountry(countryNamesArray);
    }

    _validateForms(eventNamesArray, eventIDsArray, namesArray, taxDeductionsArray, nricsArray);
  };

  useEffect(() => {
    const multipliersArray: number[] = [];
    const eventNamesArray: string[] = [];
    const eventIDsArray: string[] = [];
    const namesArray: string[] = [];
    const taxDeductionsArray: boolean[] = [];
    const nricsArray: string[] = [];
    const phoneNumbersArray: string[] = [];
    const buildingNamesArray: string[] = [];
    const unitNumbersArray: string[] = [];
    const postalCodesArray: string[] = [];
    const countriesArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const hasTax = formItems[`${i}`]['hasTaxDeduction'] ? formItems[`${i}`]['hasTaxDeduction'] : false;

        const checkbox = document.getElementById('tax_' + i) as HTMLInputElement | null; 

        if (checkbox) {
          checkbox.checked = hasTax;
        }

        const names: string[] = formItems[`${i}`][`${'names'}`];

        let name = '';

        names.forEach((item, index) => name += index != 0 && index < namesCount ? ';' + item : item);

        const eventName: string = formItems[`${i}`]['eventName'];

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        namesArray.push(name);
        taxDeductionsArray.push(hasTax);
        nricsArray.push(formItems[`${i}`]['nric'] ? formItems[`${i}`]['nric'] : '');
        phoneNumbersArray.push(formItems[`${i}`]['phoneNumber']);
        buildingNamesArray.push(formItems[`${i}`]['buildingName']);
        unitNumbersArray.push(formItems[`${i}`]['unitNumber']);
        postalCodesArray.push(formItems[`${i}`]['postalCode']);
        countriesArray.push(formItems[`${i}`]['country']);
      }

      setNames(namesArray);
      setPhoneNumbers(phoneNumbersArray);

      callbackName(namesArray);
      callbackPhoneNumber(phoneNumbersArray);
    } else {
      multipliersArray.push(1);
      eventNamesArray.push('');
      eventIDsArray.push('');
      namesArray.push('');
      taxDeductionsArray.push(false);
      nricsArray.push('');
      buildingNamesArray.push('');
      unitNumbersArray.push('');
      postalCodesArray.push('');
      countriesArray.push('');
    }

    setEventNames(eventNamesArray);
    setEventIDs(eventIDsArray);
    setMultiplers(multipliersArray);
    setTaxDeductions(taxDeductionsArray);
    setNRICs(nricsArray);
    setBuildingNames(buildingNamesArray);
    setUnitNumbers(unitNumbersArray);
    setPostalCodes(postalCodesArray);
    setCountryNames(countriesArray);

    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackMultiplier(multipliersArray);
    callbackHasTaxDeduction(taxDeductionsArray);
    callbackNRIC(nricsArray);
    callbackBuilding(buildingNamesArray);
    callbackUnitNumber(unitNumbersArray);
    callbackPostalCode(postalCodesArray);
    callbackCountry(countriesArray);

    _validateForms(eventNamesArray, eventIDsArray, namesArray, taxDeductionsArray, nricsArray);
  }, [formItems]);

  const _buildEventDropdown = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.eventDate}</p>
          <Select
            isMulti
            value={_displayEventDates(idx)}
            closeMenuOnSelect={false}
            options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
            onChange={event => _setEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  };

  const _buildName = (idx: number): JSX.Element[] => {
    const nameLabel = formLabels.name;
    const namesArray = Array.apply(null, Array(namesCount)).map(function (x, i) { return i; });

    return namesArray.map(nameIndex => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{namesCount > 1 ? (nameLabel + ' ' + (nameIndex + 1)) : nameLabel}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayNames(names[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={event => _formatNameInput(event, idx, nameIndex)}
              onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
              autoComplete='none'
            />
          </div>
        </div>
      );
    });
  };

  const _buildTaxDeductionField = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-9 form-field'>
          <p className='form-label'>{formLabels.tax}</p>
          <p className='prompt'>{formLabels.taxPrompt}</p>
          <div>
            <label htmlFor={'tax_' + idx} className='checkbox-option'>
              <input type='checkbox' id={'tax_' + idx} onChange={event => _setTaxDeduction(event.target.checked, idx)}/>
              {resource.taxDeductionLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    );
  };

  const _buildNRIC = (idx: number): JSX.Element => {
    const result = _validateIdentity(nrics[`${idx}`]);
    
    return (
      <div className='row'>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.nric}</p>
          <div className={result ? 'input-wrapper' : 'input-wrapper warning'}>
            <input
              aria-live='polite'
              className='form-control'
              inputMode='text'
              role='spinbutton'
              onChange={event => _setNric(event.target.value.toUpperCase(), idx)}
              onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
              value={nrics[`${idx}`]}
              type='text'
              maxLength={10}
              autoComplete='none'
            />
          </div>
        </div>
      </div>
    );
  };

  const _buildPhone = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.addressPhonePrompt}</p>
        <div className='col-lg-5 form-field'>
          <p className='form-label'>{formLabels.addressPhone}</p>
          <input
            type='number'
            inputMode='tel'
            value={phoneNumbers[`${idx}`]}
            aria-live='polite'
            role='spinbutton'
            onChange={event => _setPhoneNumbers(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            className='form-control'
            autoComplete='none'
          />
        </div>
      </div>
    );
  };

  const _buildAddress = (idx: number): JSX.Element => {
    const states = countries[`${localeCode}`].countries;

    return (
      <div className='row'>
        <p className='col-12 prompt'>{formLabels.addressOptional}</p>
        <p className='col-12 prompt'>{formLabels.addressPrompt}</p>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.addressBuilding}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={event => _setBuildingName(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={buildingNames[`${idx}`]}
            type='text'
            autoComplete='none'
          />

          <p className='form-label'>{formLabels.addressUnit}</p>
          <input
            aria-live='polite'
            className='form-control'
            inputMode='text'
            role='spinbutton'
            onChange={event => _setUnitNumber(event.target.value, idx)}
            onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
            maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            value={unitNumbers[`${idx}`]}
            type='text'
            autoComplete='none'
          />
        </div>

        <div className='col-lg-10 row'>
          <div className='col-lg-4 form-field'>
            <p className='form-label'>{formLabels.addressPostalCode}</p>
            <input
              type='text'
              inputMode='text'
              value={postalCodes[`${idx}`]}
              aria-live='polite'
              role='spinbutton'
              onChange={event => _setPostalCode(event.target.value, idx)}
              onKeyDown={event => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              autoComplete='none'
            />
          </div>

          <div className='col-lg-5 offset-lg-1 form-field'>
            <p className='form-label'>{formLabels.addressCountry}</p>
            <Select
              placeholder={countryNames[`${idx}`]}
              options={states}
              onChange={event => _setCountryName(event, idx)}
              className={'select'}
              isSearchable={true}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'event') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildEventDropdown(idx)}
                          {_buildName(idx)}
                          {_buildTaxDeductionField(idx)}
                          {taxDeductions[`${idx}`] && _buildNRIC(idx)}
                          {taxDeductions[`${idx}`] && _buildPhone(idx)}
                          {taxDeductions[`${idx}`] && _buildAddress(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${multipliers[`${idx}`] * selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default EventTaxDeductionForm;