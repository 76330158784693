import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import resource from '../resources/resource.json';

import Select from 'react-select'

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackMultiplier: any;
  callbackEvent?: any;
  callbackEventID?: any;
  callbackName?: any;
  callbackCategory?: any;
  callbackEventsSelected?: any;
  callbackNamesEntered?: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  productEvents: any[];
  allowMultipleRegistrations: boolean;
}

const EventCategoryNamesForm: React.FC<IEcommFormProps> = ({ formScheme, selectedProduct, rowsData, addRow, deleteRow, callbackMultiplier, callbackEvent, callbackEventID, callbackName, callbackCategory, callbackEventsSelected, callbackNamesEntered, formItems, config, localeCode, actionContext, isMobile, isEditMode, productEvents,allowMultipleRegistrations }) => {
  const [selectValue, setSelectValue] = useState(['']);
  const [ceremonyEventID, setEventID] = useState(['']);
  const [radioValue, setRadioValue] = useState([resource.categoryFamilyLabel[`${localeCode}`]]);
  const [ceremonyName, setCeremonyName] = useState(['']);

  const [multipliers, setMultiplers] = useState([1]);

  const formLabels = resource.eventCategoryNamesForm[`${localeCode}`];
  const noOfNames = formScheme.find(form => form.field_type === 'textBox')!.field_number!;
  const companyNames = formScheme.find(form => form.field_type === 'textBox')!.field_number2!;

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  };

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset } = useForm();

  const _setMultipler = (count: number, index: number) => {
    const valueArray = multipliers.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push(1);
    }

    valueArray[`${index}`] = count;

    setMultiplers(valueArray);

    callbackMultiplier(valueArray);
  }

  const _onChangeRadioValue = (event: any, index: number) => {
    _setRadioValueArray(event.target.value, index);

    let ceremonyNameArray = ceremonyName.slice();
    let existingCeremonyName = ceremonyNameArray[`${index}`];
    let existingNameArray = existingCeremonyName.split(';');

    if (event.target.value === resource.categoryFamilyLabel[`${localeCode}`]) {
      if (existingNameArray.length < noOfNames) {
        existingCeremonyName += ';'.repeat(noOfNames - existingNameArray.length - 1);
      } else { // More than
        while (existingNameArray.length > noOfNames) {
          existingNameArray.pop();
        }
        existingCeremonyName = existingNameArray.join(';');
      }
    } else {
      if (existingNameArray.length < companyNames) {
        existingCeremonyName += ';'.repeat(companyNames - existingNameArray.length - 1);
      } else { // More than
        while (existingNameArray.length > companyNames) {
          existingNameArray.pop();
        }
        existingCeremonyName = existingNameArray.join(';');
      }
    }

    ceremonyNameArray[`${index}`] = existingCeremonyName;

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);
  }

  const _setRadioValueArray = (value: string, index: number) => {
    const radioValueArray = radioValue.slice();

    while (typeof radioValueArray[`${index}`] === 'undefined') {
      radioValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    radioValueArray[`${index}`] = value;

    setRadioValue(radioValueArray);

    callbackCategory(radioValueArray);
  }

  const _displayName = (value: string, nameIndex: number): string => {
    if (value) {
      const names = value.split(';');

      return names[`${nameIndex}`] ? names[`${nameIndex}`] : '';
    }

    return ''
  }

  const _setNameMultipleInputValue = (event: any, index: number, nameIndex: number, noOfNames: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    let inputName = event.target.value.substring(0, maxLength - 1);
    let inputMultipleName = ceremonyName[`${index}`];

    if (!inputMultipleName.includes(';')) {
      inputMultipleName += ';'.repeat(noOfNames - 1);
    }

    const inputMutlipleNameSplit = inputMultipleName.split(';');

    inputMutlipleNameSplit[`${nameIndex}`] = inputName;
    inputName = inputMutlipleNameSplit.join(';');

    _setCeremonyNameArray(inputName, index);
  }

  const _setCeremonyNameArray = (name: string, index: number) => {
    const ceremonyNameArray = ceremonyName.slice();

    while (typeof ceremonyNameArray[`${index}`] === 'undefined') {
      ceremonyNameArray.push('');
    }

    ceremonyNameArray[`${index}`] = name;

    ceremonyNameArray[`${index}`].trim();

    setCeremonyName(ceremonyNameArray);

    callbackName(ceremonyNameArray);

    _checkNamesEntered(ceremonyNameArray);
  }

  const _setMultipleEventDates = (event: any, index: number) => {
    let inputEventDates: string = '';
    let inputEventIDs: string = '';

    for (let i = 0; i < event.length; ++i) {
      inputEventDates += event[`${i}`].label + (i != event.length - 1 ? ';' : '');
      inputEventIDs += event[`${i}`].value + (i != event.length - 1 ? ';' : '');
    }

    const selectValueArray = selectValue.slice();
    const eventIDArray = ceremonyEventID.slice();

    while (typeof selectValueArray[`${index}`] === 'undefined') {
      selectValueArray.push('');
    }

    while (typeof eventIDArray[`${index}`] === 'undefined') {
      eventIDArray.push('');
    }

    selectValueArray[`${index}`] = inputEventDates;
    eventIDArray[`${index}`] = inputEventIDs;

    setSelectValue(selectValueArray);
    setEventID(eventIDArray);

    callbackEvent(selectValueArray);
    callbackEventID(eventIDArray);

    _setMultipler(event.length, index);

    _checkEventsEntered(selectValueArray);
  }

  const _displayEventDates = (index: number): { value: string, label: string }[] => {
    const displayValue: { value: string, label: string }[] = [];

    if (selectValue.length > 0) {
      const selectValueArray = selectValue.slice();
      const eventIDArray = ceremonyEventID.slice();

      if (typeof selectValueArray[`${index}`] !== 'undefined') {
        if (selectValueArray[`${index}`] !== '') {
          const eventDates = selectValueArray[`${index}`].split(';');
          const eventIds = eventIDArray[`${index}`].split(';');

          for (let i = 0; i < eventDates.length; ++i) {
            displayValue.push({ value: eventIds[`${i}`], label: eventDates[`${i}`] });
          }
        }
      }
    }

    return displayValue;
  }

  const _checkEventsEntered = (ceremonyEventArray: string[]) => {
    let eventsSelected = ceremonyEventArray.length > 0 ? true : false;

    for (let i = 0; i < ceremonyEventArray.length; i++) {
      if (ceremonyEventArray[`${i}`].replace(/;/g, '').trim() === '') {
        eventsSelected = false; break;
      }
    }

    callbackEventsSelected(eventsSelected);
  }

  const _checkNamesEntered = (ceremonyNameArray: string[]) => {
    let namesEntered = ceremonyNameArray.length > 0 ? true : false;

    for (let i = 0; i < ceremonyNameArray.length; i++) {
      if (ceremonyNameArray[`${i}`].replace(/;/g, '').trim() === '') {
        namesEntered = false; break;
      }
    }

    callbackNamesEntered(namesEntered);
  }

  const _checkNumberOfSelectedEvent = (index: number): number => {
    const count = selectValue.length > 0 && typeof selectValue[`${index}`] !== 'undefined' ? selectValue[`${index}`].split(';').length : 1;

    return count;
  }

  const _addItem = () => {
    addRow(1);

    const multiplierArray = multipliers.slice();
    const eventNamesArray = selectValue.slice();
    const eventIDsArray = ceremonyEventID.slice();
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();

    multiplierArray.push(1);
    eventNamesArray.push('');
    eventIDsArray.push('');
    categoryValueArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    ceremonyNameArray.push('');

    setMultiplers(multiplierArray);
    setSelectValue(eventNamesArray);
    setEventID(eventIDsArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);

    callbackMultiplier(multiplierArray);
    callbackEvent(eventNamesArray);
    callbackEventID(eventIDsArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);

    callbackEventsSelected(false);
    callbackNamesEntered(false);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const multiplierArray = multipliers.slice();
    const ceremonyEventArray = selectValue.slice();
    const ceremonyEventIDArray = ceremonyEventID.slice();
    const categoryValueArray = radioValue.slice();
    const ceremonyNameArray = ceremonyName.slice();

    if (typeof multiplierArray[`${index}`] !== 'undefined') {
      if (multiplierArray.length > 1) {
        multiplierArray.splice(index, 1);
      } else {
        multiplierArray[0] = 1;
      }

      setMultiplers(multiplierArray);

      callbackMultiplier(multiplierArray);
    }

    if (typeof ceremonyEventArray[`${index}`] !== 'undefined') {
      if (ceremonyEventArray.length > 1) {
        ceremonyEventArray.splice(index, 1);
      } else {
        ceremonyEventArray[0] = '';
      }
    }

    if (typeof ceremonyEventIDArray[`${index}`] !== 'undefined') {
      if (ceremonyEventIDArray.length > 1) {
        ceremonyEventIDArray.splice(index, 1);
      } else {
        ceremonyEventIDArray[0] = '';
      }
    }

    if (typeof categoryValueArray[`${index}`] !== 'undefined') {
      if (categoryValueArray.length > 1) {
        categoryValueArray.splice(index, 1);
      } else {
        categoryValueArray[0] = resource.categoryFamilyLabel[`${localeCode}`];
      }
    }

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }
    }

    setSelectValue(ceremonyEventArray);
    setEventID(ceremonyEventIDArray);
    setRadioValue(categoryValueArray);
    setCeremonyName(ceremonyNameArray);

    callbackEvent(ceremonyEventArray);
    callbackEventID(ceremonyEventIDArray);
    callbackCategory(categoryValueArray);
    callbackName(ceremonyNameArray);

    _checkEventsEntered(ceremonyEventArray);
    _checkNamesEntered(ceremonyNameArray);
  }

  useEffect(() => {
    const eventIDsArray: string[] = [];
    const eventNamesArray: string[] = [];
    const multipliersArray: number[] = [];
    const namesArray: string[] = [];
    const categoriesArray: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const itemNames: string[] = formItems[`${i}`]['names'];
        const itemCategory = formItems[`${i}`]['category'];
        const itemNameCount = itemCategory === resource.categoryFamilyLabel[`${localeCode}`] ? noOfNames : companyNames;

        let itemName = '';

        itemNames.map((name, index) => {
          if (index !== 0 && index < itemNameCount) {
            itemName += ';' + name;
          } else {
            itemName += name;
          }
        });

        const eventName: string = formItems[`${i}`]['eventName'];

        multipliersArray.push(eventName.split(';').length);
        eventNamesArray.push(eventName);
        eventIDsArray.push(formItems[`${i}`]['eventID']);
        namesArray.push(itemName);
        categoriesArray.push(itemCategory);
      }

      setCeremonyName(namesArray);

      callbackName(namesArray);

      _checkNamesEntered(namesArray);
    } else {
      eventNamesArray.push('');
      eventIDsArray.push('');
      multipliersArray.push(1);
      categoriesArray.push(resource.categoryFamilyLabel[`${localeCode}`]);
    }

    setEventID(eventIDsArray);
    setSelectValue(eventNamesArray);
    setMultiplers(multipliersArray);
    setRadioValue(categoriesArray);

    callbackEventID(eventIDsArray);
    callbackEvent(eventNamesArray);
    callbackMultiplier(multipliersArray);
    callbackCategory(categoriesArray);

    _checkEventsEntered(eventNamesArray);
  }, [formItems])

  const _buildEventDropdown = (idx: number): JSX.Element => {
    return (
      <div className='row'>
        <div className='col-lg-6 form-field'>
          <p className='form-label'>{formLabels.eventDate}</p>
          <Select
            isMulti
            value={_displayEventDates(idx)}
            closeMenuOnSelect={false}
            options={productEvents.map(event => { return ({ value: event['id'], label: event['name'] }); })}
            onChange={event => _setMultipleEventDates(event, idx)}
            classNamePrefix='eventSelect'
            className='select'
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
          />
        </div>
      </div>
    );
  }

  const _buildCategoryField = (idx: number): JSX.Element | null => {
    if (typeof radioValue[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <p className='form-label'>{formLabels.category}</p>
          <div className='form-field row'>
            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.categoryFamilyLabel[`${localeCode}`]}
                checked={radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`])}
                aria-checked={radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`])}
                onChange={(event) => _onChangeRadioValue(event, idx)}
              />
              {resource.categoryFamilyLabel[`${localeCode}`]}
            </label>

            <label className='radio-option col-12 col-lg-3'>
              <input
                type='radio'
                value={resource.categoryCompanyLabel[`${localeCode}`]}
                checked={radioValue[`${idx}`] === String(resource.categoryCompanyLabel[`${localeCode}`])}
                aria-checked={radioValue[`${idx}`] === String(resource.categoryCompanyLabel[`${localeCode}`])}
                onChange={(event) => _onChangeRadioValue(event, idx)}
              />
              {resource.categoryCompanyLabel[`${localeCode}`]}
            </label>
          </div>
        </div>
      </div>
    )
  }

  const _buildNameGroup = (idx: number, noOfNames: number): JSX.Element[] => {
    const nameLabel = formLabels.name;
    const namesArray = Array.apply(null, Array(noOfNames)).map(function (x, i) { return i; });

    return namesArray.map((nameIndex) => {
      return (
        <div className='row'>
          <div className='col-lg-6 form-field'>
            <p className='form-label'>{nameLabel.endsWith('*') ? nameIndex === 0 ? nameLabel.substring(0, nameLabel.length - 1) + ' ' + (nameIndex + 1) + '*' : nameLabel.replace('*', ' ' + (nameIndex + 1)) : nameLabel + ' ' + nameIndex + 1}</p>
            <input
              type='text'
              inputMode='text'
              value={_displayName(ceremonyName[`${idx}`], nameIndex)}
              aria-live='polite'
              role='spinbutton'
              onChange={(event) => _setNameMultipleInputValue(event, idx, nameIndex, noOfNames)}
              onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
              className='form-control'
              maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_type === 'dropdown') {
                      return (
                        <div className='form-details-item col-lg-9'>
                          {_buildEventDropdown(idx)}
                          {_buildCategoryField(idx)}
                          {radioValue[`${idx}`] === String(resource.categoryFamilyLabel[`${localeCode}`]) && noOfNames > 0 && _buildNameGroup(idx, noOfNames)}
                          {radioValue[`${idx}`] === String(resource.categoryCompanyLabel[`${localeCode}`]) && companyNames > 0 && _buildNameGroup(idx, companyNames)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price! * _checkNumberOfSelectedEvent(idx)}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>}
      </form>
    </div>
  );
};

export default EventCategoryNamesForm;