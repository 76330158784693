import React, { useState, useEffect } from 'react';

import DatePicker from 'react-date-picker';
import Select from 'react-select';

import { useForm } from 'react-hook-form';

import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, AsyncResult } from '@msdyn365-commerce/retail-proxy';

import { deleteBTGPCartLineAsync, getAllBTGPCartLineAsync } from '../../../actions/DataActionExtension.g';

import resource from '../resources/resource.json';
import time from '../resources/time.json';

import { IField, IdState } from '../ecomm-product-details-form.data';
import { IEcommProductDetailsFormConfig } from '../ecomm-product-details-form.props.autogenerated';

interface IEcommFormProps {
  formScheme: IField[];
  selectedProduct: AsyncResult<SimpleProduct>;
  rowsData: IdState[];
  addRow(noOfRows: Number): void;
  deleteRow(idx: number): void;
  callbackName?: any;
  callbackBirthDate: any;
  callbackBirthTime: any;
  callbackNamesEntered?: any;
  callbackDateEntered: any;
  formItems: [];
  config: IEcommProductDetailsFormConfig;
  localeCode: string;
  actionContext: any;
  isMobile: boolean;
  isEditMode: boolean;
  allowMultipleRegistrations: boolean;
}

const NameBirthDatetimeForm: React.FC<IEcommFormProps> = ({
  formScheme, selectedProduct, rowsData, addRow, deleteRow,
  callbackName, callbackBirthDate, callbackBirthTime,
  callbackNamesEntered, callbackDateEntered,
  formItems, config, localeCode, actionContext, isMobile, isEditMode, allowMultipleRegistrations }) => {
  const [ceremonyName, setCeremonyName] = useState(['']);
  const [birthDate, setBirthDate] = useState(['']);
  const [birthTime, setBirthTime] = useState(['']);

  const formLabels = resource.nameBirthDatetimeForm[`${localeCode}`];

  const onSubmit = (data: any) => {
    // display form data on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
  }

  // Functions to build form returned by useForm() hook.
  const { handleSubmit, reset } = useForm();

  const _setNameInputValue = (event: any, index: number) => {
    const maxLength = config.charactersLimitForNames ? config.charactersLimitForNames : 31;

    _setCeremonyNameArray(event.target.value.substring(0, maxLength - 1), index);
  }

  const _setCeremonyNameArray = (name: string, index: number) => {
    const valueArray = ceremonyName.slice();

    // To handle adding of a new form record.
    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = name;

    valueArray[`${index}`].trim();

    setCeremonyName(valueArray);

    callbackName(valueArray);

    _checkNameEntered(valueArray);
  }

  const _setBirthDate = (value: Date, index: number) => {
    const valueArray = birthDate.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = value ? value.toISOString() : '';

    setBirthDate(valueArray);

    callbackBirthDate(valueArray);

    _checkDateEntered(valueArray);
  }

  const _setBirthTime = (event: any, index: number) => {
    const valueArray = birthTime.slice();

    while (typeof valueArray[`${index}`] === 'undefined') {
      valueArray.push('');
    }

    valueArray[`${index}`] = event.label;

    setBirthTime(valueArray);

    callbackBirthTime(valueArray);
  }

  const _checkNameEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`].replace(/;/g, '').trim() === '') {
        checked = false; break;
      }
    }

    callbackNamesEntered(checked);
  }

  const _checkDateEntered = (valueArray: string[]) => {
    let checked = true;

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[`${i}`] === null) {
        checked = false; break;
      }
    }

    callbackDateEntered(checked);
  }

  const _addItem = () => {
    addRow(1);

    const ceremonyNameArray = ceremonyName.slice();
    const dateValueArray = birthDate.slice();
    const timeValueArray = birthTime.slice();

    ceremonyNameArray.push('');
    dateValueArray.push('');
    timeValueArray.push('');

    setCeremonyName(ceremonyNameArray);
    setBirthDate(dateValueArray);
    setBirthTime(timeValueArray);

    callbackName(ceremonyNameArray);
    callbackBirthDate(dateValueArray);
    callbackBirthTime(timeValueArray);

    callbackNamesEntered(false);
    callbackDateEntered(true);
  }

  const _deleteCartLine = async (index: number): Promise<void> => {
    if (typeof formItems[`${index}`] !== 'undefined') {
      const cartState = await getCartState(actionContext);

      let success = false;

      const cartItems = await getAllBTGPCartLineAsync({ callerContext: actionContext }, cartState.cart.Id);

      if (cartItems.length > 0) {
        let removeCartLinesResult = await cartState.removeCartLines({ cartLineIds: [formItems[`${index}`]['cartLineId']] });

        if (removeCartLinesResult.status === 'SUCCESS') {
          success = true;
        }

        let deleteCartLineResult = await deleteBTGPCartLineAsync({ callerContext: actionContext }, formItems[`${index}`]['cartLineId']);

        if (success && deleteCartLineResult) {
          deleteRow(index);
          formItems.splice(index, 1);
          _updateStateData(index);
        }

      } else {
        deleteRow(index);
        _updateStateData(index);
      }
    } else {
      deleteRow(index);
      _updateStateData(index);
    }
  }

  const _updateStateData = (index: number) => {
    const ceremonyNameArray = ceremonyName.slice();
    const birthDateArray = birthDate.slice();
    const timeValueArray = birthTime.slice();

    if (typeof ceremonyNameArray[`${index}`] !== 'undefined') {
      if (ceremonyNameArray.length > 1) {
        ceremonyNameArray.splice(index, 1);
      } else {
        ceremonyNameArray[0] = '';
      }

      setCeremonyName(ceremonyNameArray);

      callbackDateEntered(ceremonyNameArray);
    }

    if (typeof birthDateArray[`${index}`] !== 'undefined') {
      if (birthDateArray.length > 1) {
        birthDateArray.splice(index, 1);
      } else {
        birthDateArray[0] = '';
      }

      setBirthDate(birthDateArray);

      callbackName(birthDateArray);
    }

    if (typeof timeValueArray[`${index}`] !== 'undefined') {
      if (timeValueArray.length > 1) {
        timeValueArray.splice(index, 1);
      } else {
        timeValueArray[0] = '';
      }

      setBirthTime(timeValueArray);

      callbackBirthTime(timeValueArray);
    }

    _checkNameEntered(ceremonyNameArray);
    _checkDateEntered(birthDateArray);
  }

  useEffect(() => {
    const existingCeremonyName: string[] = [];
    const existingBirthDate: string[] = [];
    const existingBirthTime: string[] = [];

    if (formItems.length > 0) {
      for (let i = 0; i < formItems.length; i++) {
        const birthDate = formItems[`${i}`]['birthDate'] !== '' ? new Date(formItems[`${i}`]['birthDate']) : new Date();

        existingCeremonyName.push(formItems[`${i}`]['names'][0]);
        existingBirthDate.push(birthDate.toISOString());
        existingBirthTime.push(formItems[`${i}`]['birthTime']);
      }

      setCeremonyName(existingCeremonyName);
      setBirthDate(existingBirthDate);
      setBirthTime(existingBirthTime);

      callbackName(existingCeremonyName);
      callbackBirthDate(existingBirthDate);
      callbackBirthTime(existingBirthTime);

      _checkNameEntered(existingCeremonyName);
      _checkDateEntered(existingBirthDate);
    }
  }, [formItems])

  const _buildNameField = (idx: number): JSX.Element => {
    return (
      <div className='col-lg-4 form-field'>
        <p className='form-label'>{formLabels.name}</p>
        <input
          type='text'
          inputMode='text'
          value={ceremonyName[`${idx}`]}
          aria-live='polite'
          role='spinbutton'
          onChange={(event) => _setNameInputValue(event, idx)}
          onKeyDown={(event) => { if (event.keyCode === 13) { event.preventDefault(); } }}
          className='form-control'
          maxLength={config.charactersLimitForNames ? config.charactersLimitForNames : 31}
        />
      </div>
    );
  }

  const _buildBirthDate = (idx: number): JSX.Element | null => {
    if (typeof birthDate[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='col-lg-3 offset-lg-1 form-field'>
        <p className='form-label'>{formLabels.birthDate}</p>
        <DatePicker
          onChange={(date: Date) => _setBirthDate(date, idx)}
          value={birthDate[`${idx}`] === '' || birthDate[`${idx}`].includes('1900') ? null : new Date(birthDate[`${idx}`])}
          format={'dd-MM-y'}
          minDate={new Date(1900,1,1)}
          maxDate={new Date()}
          className={'datepicker'}
          dayPlaceholder={''}
          monthPlaceholder={''}
          yearPlaceholder={''}
        />
      </div>
    );
  }

  const _buildBirthTime = (idx: number): JSX.Element | null => {
    if (typeof birthTime[`${idx}`] === 'undefined') {
      return null;
    }

    return (
      <div className='col-lg-3 offset-lg-1 form-field'>
        <p className='form-label'>{formLabels.birthTime}</p>
        <Select
          placeholder={birthTime[`${idx}`]}
          options={time.intervals}
          onChange={(event) => _setBirthTime(event, idx)}
          className={'select'}
          isSearchable={false}
        />
      </div>
    );
  }

  return (
    <div className='ecomm-purchase-form'>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset} autoComplete='off'>
        <div className='ecomm-purchase-form-table'>
          {rowsData.map((i, idx) => {
            return (
              <div className='form-record'>
                <div key={`row${i.id}`} className='form-details row'>
                  {formScheme.map((element: IField, index: number) => {

                    if (element.field_name === 'name') {
                      return (
                        <div className='form-details-item col-lg-9 row'>
                          {_buildNameField(idx)}
                          {_buildBirthDate(idx)}
                          {_buildBirthTime(idx)}
                        </div>
                      );
                    }

                    if (!isMobile && element.field_type === 'product_price') {
                      return (
                        <div className='form-details-item bordered centred col-2'>
                          <p key={index} className='subtotal'>${selectedProduct.result?.Price!}</p>
                        </div>
                      );
                    }

                    if (element.field_type === 'action') {
                      if (isMobile) {
                        const style = !isEditMode ? 'form-details-item' : 'form-details-item edit';

                        return (
                          <div className={style}>
                            {!isEditMode && allowMultipleRegistrations && <div className='col-10'>
                              <button className='add-row' type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
                            </div>}
                            <div className='delete-wrapper col-2'><button className={element.action} onClick={() => _deleteCartLine(idx)} /></div>
                          </div>
                        );
                      }

                      return (
                        <div className='form-details-item centred col-sm-1'>
                          <button className={element.action} onClick={() => _deleteCartLine(idx)} />
                        </div>
                      );
                    }

                    return;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!isMobile && !isEditMode && allowMultipleRegistrations &&
          <div className='ecomm-purchase-form-add'>
            <button type='submit' onClick={_addItem}>{resource.addAnotherItemTitle[`${localeCode}`]}</button>
          </div>
        }
      </form>
    </div>
  );
};

export default NameBirthDatetimeForm;